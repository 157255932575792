import React, { useContext, useEffect, useState } from "react";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { Header } from "components/common/header/Header";
import AvailableCourses from "components/module/app/myCourses/AvailableCourses";
import EnrolledCourses from "components/module/app/myCourses/EnrolledCourses";
import LoadingContext from "context/loadingContext/LoadingContext";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { SkeletonLoader } from "components/common/skeleton/SkeletonCard";

const MyCoursePage = () => {
  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);

  const { isAppLoading } = useContext(LoadingContext);

  const [enrolledCoursesDetails, setEnrolledCoursesDetails] = useState([]);
  const [availableCoursesDetails, setAvailableCoursesDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  //trigger the get api reguest with just the token as header
  const {
    data: getCoursesData,
    loading: loadingCourses,
    error: errorFetchingCourses,
    getData: coursesPayload,
  } = useGetRequest(API_ENDPOINTS.COURSES_FOR_USER);
  const {
    data: getAvailableCoursesData,
    loading: loadingAvailableCourses,
    error: errorFetchingAvailableCourses,
    getData: AvailableCoursesPayload,
  } = useGetRequest(API_ENDPOINTS.AVAILABLE_COURSES_FOR_USER);

  //trigger the api when the component renders

  useEffect(() => {
    const fetchCourses = async () => {
      if (!isAppLoading) {
        // console.log("is app loading:", isAppLoading);
        try {
          await coursesPayload();
        } catch (err) {
          if (err.response) {
            notifyUser(
              "Error",
              "An error occurred while fetching courses.",
              "error"
            );
          } else if (err.request) {
            notifyUser(
              "Network Error",
              "Network error, please try again.",
              "warning"
            );
          } else {
            notifyUser(
              "Unexpected Error",
              `Unexpected error: ${err.message}`,
              "error"
            );
          }
        }
      }
    };
    fetchCourses();
  }, [isAppLoading]);
  useEffect(() => {
    const fetchAvailableCourses = async () => {
      if (!isAppLoading) {
        // console.log("is app loading:", isAppLoading);
        try {
          await AvailableCoursesPayload();
        } catch (err) {
          if (err.response) {
            notifyUser(
              "Error",
              "An error occurred while fetching courses.",
              "error"
            );
          } else if (err.request) {
            notifyUser(
              "Network Error",
              "Network error, please try again.",
              "warning"
            );
          } else {
            notifyUser(
              "Unexpected Error",
              `Unexpected error: ${err.message}`,
              "error"
            );
          }
        }
      }
    };
    fetchAvailableCourses();
  }, [isAppLoading]);

  const mergeCourseData = (enrolledCoursesDetails, courseStat, progressData, certificateData = []) =>
    enrolledCoursesDetails.map((course) => {
      const {
        course_id,
        enrollment_id
      } = course;

      // Find matching statistics, progress, and certificate for the course
      const courseStatDetails = courseStat.find((stat) => stat.course_id === course_id);
      const courseProgress = progressData.find((progress) => progress.course_id === course_id);
      const courseCertificate = certificateData.find(
        (cert) => cert.course_id === course_id && cert.enrollment_id === enrollment_id
      );

      // Calculate progress percentage, handling cases where data may be missing
      console.log("courseStatDetails ---> ", courseStatDetails);
      console.log(courseProgress);
      const progress =
      courseStatDetails?.total_lessons && courseProgress?.count
        ? Math.min(
            Math.round(((courseProgress.count) / courseStatDetails.total_lessons) * 100),
            100
          )
        : 0;

      return {
        ...course,
        avg_review: courseStatDetails?.avg_review,
        progress,
        certificateCode: courseCertificate?.certificate_code || null, // Handle missing certificates gracefully
      };
    });

  useEffect(() => {
    if (!loadingCourses) {
      if (errorFetchingCourses) {
        console.log("Error fetching the courses details from the API");
      } else if (getCoursesData) {
        // console.log("getCoursesData ---> ", getCoursesData);

        const enrolledCourses = mergeCourseData(
          getCoursesData.enrolledCoursesDetails || [],
          getCoursesData.CourseStat || [],
          getCoursesData.progressData || [],
          getCoursesData.certificateData || []
        );

        setEnrolledCoursesDetails(enrolledCourses);
        setLoading(false);
      }
    }
  }, [loadingCourses, getCoursesData]);
  useEffect(() => {
    if (!loadingAvailableCourses) {
      if (errorFetchingAvailableCourses) {
        console.log("Error fetching the courses details from the API");
      } else if (getAvailableCoursesData) {
        // console.log("get AvailableCoursesData ---> ", getAvailableCoursesData);

        const availableCourses = mergeCourseData(
          getAvailableCoursesData.availableCoursesDetails || [],
          getAvailableCoursesData.CourseStat || [],
          getAvailableCoursesData.progressData || [],
          []
        );

        setAvailableCoursesDetails(availableCourses);
        setLoading(false);
      }
    }
  }, [loadingAvailableCourses, getAvailableCoursesData]);


  // console.log("enrolled courses data:", enrolledCoursesDetails);
  // console.log("available  courses data:", availableCoursesDetails);

  return (
    <>
      <Header title="Courses" subtitle="Never stop learning"  />
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          {enrolledCoursesDetails.length > 0 && (
            <EnrolledCourses enrolledCoursesDetails={enrolledCoursesDetails} />
          )}
          {availableCoursesDetails.length > 0 && (
            <AvailableCourses
              coursesText={"Courses"}
              availableCoursesDetails={availableCoursesDetails}
            />
          )}
        </>
      )}
    </>
  );
};

export default MyCoursePage;