import { Box, Button } from "@mui/material";
import React, { useState, useRef, useEffect, useContext } from "react";
import QuizFeedback from "./QuizFeedback";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import LessonContext from "context/lessonContext/LessonContext";
import QuizTimer from "./TimerCard";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";
import QuizResultCard from "./QuizResultCard";

// Constants for word limits
const MIN_WORDS = 1;
const MAX_WORDS = 1;

const HtmlCodeQuiz = ({ content }) => {
  const { lessonInfo, lessonIdentifiers, updateLessonCompletionData } = useContext(LessonContext);
  const { notifyUser } = useContext(NofityUserContext);
  const [display, setLoading] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const questionRef = useRef(null);
  const [timerKey, setTimerKey] = useState(0);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [invalidCells, setInvalidCells] = useState(new Set());
  const [duplicateWords, setDuplicateWords] = useState(new Set());

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  const { postData: saveQuizResponse } =
    usePostRequest(API_ENDPOINTS.SAVE_HTML_QUIZ_PROGRESS);

  // Helper function to count words in a string
  const countWords = (text) => {
    const trimmedText = text.trim();
    return trimmedText ? trimmedText.split(/\s+/).length : 0;
  };

  // Helper function to validate word count
  const isWordCountValid = (text) => {
    const wordCount = countWords(text);
    return wordCount === MIN_WORDS;
  };

  // Function to validate input and handle cell styling
  const validateWordCount = (cell) => {
    const text = cell.textContent.trim();
    const wordCount = countWords(text);
    const cellId = cell.getAttribute('data-cell-id');

    if (wordCount > MAX_WORDS) {
      const firstWord = text.split(/\s+/)[0];
      cell.textContent = firstWord;
      notifyUser(
        "Word Limit Exceeded",
        "Only one word is allowed per cell. Extra words have been removed.",
        "warning"
      );
    }

    if (!isWordCountValid(cell.textContent.trim())) {
      setInvalidCells(prev => new Set([...prev, cellId]));
      return false;
    } else {
      setInvalidCells(prev => {
        const newSet = new Set(prev);
        newSet.delete(cellId);
        return newSet;
      });
      return true;
    }
  };

  // Function to validate input
  const validateInput = (event) => {
    const cell = event.target;
    let text = cell.textContent;
    
    if (text !== text.trim()) {
      text = text.trim();
      cell.textContent = text;
    }
    
    const cleanText = text.replace(/[^a-zA-Z]/g, '');
    
    if (cleanText !== text) {
      event.preventDefault();
      cell.textContent = cleanText;
      notifyUser(
        "Invalid Input",
        "Only letters are allowed. Spaces, numbers and special characters will be removed.",
        "warning"
      );
    }

    validateWordCount(cell);
  };

  useEffect(() => {
    setTimerKey(timerKey + 1);
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const foundContent = lessonInfo.find(item => item.content_id === content);
      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      }
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(
        contentDetails1.RefContentType.name,
        contentDetails1.contentDetails.htmlCodeDetails.quiz_ht_id,
        lessonIdentifiers?.course_id
      );
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);

  useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true);
      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    const checkInputFieldsFilled = () => {
      const editableCells = document.querySelectorAll(
        '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
      );

      const enteredWords = new Set();
      let allValid = true;
      let hasDuplicates = false;
      const newDuplicates = new Set();

      editableCells.forEach((cell) => {
        if (cell.textContent !== cell.textContent.trim()) {
          cell.textContent = cell.textContent.trim();
        }
        
        const text = cell.textContent.trim().toLowerCase();
        
        if (!/^[a-zA-Z]*$/.test(text)) {
          cell.textContent = text.replace(/[^a-zA-Z]/g, '');
          notifyUser(
            "Invalid Input",
            "Only letters are allowed. Spaces, numbers and special characters will be removed.",
            "warning"
          );
          allValid = false;
        }

        if (!validateWordCount(cell)) {
          allValid = false;
        }

        if (text !== "") {
          if (enteredWords.has(text)) {
            newDuplicates.add(text);
            hasDuplicates = true;
            // Only notify if this is a newly discovered duplicate
            if (!duplicateWords.has(text)) {
              notifyUser(
                "Duplicate Word",
                `The word "${text}" is already used. Please enter a unique word.`,
                "error"
              );
            }
          } else {
            enteredWords.add(text);
          }
        }
      });
      
      // Update the duplicateWords state
      setDuplicateWords(newDuplicates);

      const allFilled = Array.from(editableCells).every(
        (cell) => cell.textContent.trim() !== "" && !invalidCells.has(cell.getAttribute('data-cell-id'))
      );

      setAllInputsFilled(allFilled && allValid && !hasDuplicates);
    };

    if (quizStarted) {
      const editableCells = document.querySelectorAll(
        '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
      );

      editableCells.forEach((cell, index) => {
        if (!cell.hasAttribute('data-cell-id')) {
          cell.setAttribute('data-cell-id', `cell-${index}`);
        }

        cell.addEventListener("keydown", (e) => {
          if (e.key === " ") {
            e.preventDefault();
          }
        });

        cell.addEventListener("input", validateInput);
        cell.addEventListener("input", checkInputFieldsFilled);
        
        cell.addEventListener("paste", (e) => {
          e.preventDefault();
          const text = e.clipboardData.getData("text/plain");
          const cleanWord = text.replace(/[^a-zA-Z]/g, '');
          document.execCommand("insertText", false, cleanWord);
        });

        cell.addEventListener("blur", () => {
          cell.textContent = cell.textContent.trim();
          checkInputFieldsFilled();
        });
      });

      // Initial check
      checkInputFieldsFilled();

      return () => {
        editableCells.forEach((cell) => {
          cell.removeEventListener("keydown", (e) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          });
          cell.removeEventListener("input", validateInput);
          cell.removeEventListener("input", checkInputFieldsFilled);
          cell.removeEventListener("blur", () => {
            cell.textContent = cell.textContent.trim();
            checkInputFieldsFilled();
          });
        });
      };
    }
  }, [quizStarted, invalidCells, duplicateWords]);

  useEffect(() => {
    if (quizStatApiResponse) {
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_ht_id === detail.response_ht_id
        );

        return {
          id: detail.response_ht_id,
          question: detail.MasterQuestion ? detail.MasterQuestion.question : '',
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: detail.MasterQuestion ? detail.MasterQuestion.score : 0,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });

      setModalData(combinedData);
    }
  }, [quizStatApiResponse]);

  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
    setLoading(true);
  };

  const handleSave = async () => {
    // Check for validation issues
    const editableCells = document.querySelectorAll(
      '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
    );
    
    // Check for empty cells
    const emptyCells = Array.from(editableCells).filter(cell => cell.textContent.trim() === "");
    if (emptyCells.length > 0) {
      notifyUser(
        "Validation Error",
        "All cells must be filled with one word each.",
        "error"
      );
      return;
    }
    
    // Check for invalid word counts
    if (invalidCells.size > 0) {
      notifyUser(
        "Validation Error",
        "Each cell must contain exactly one word.",
        "error"
      );
      return;
    }
    
    // Check for duplicate words
    if (duplicateWords.size > 0) {
      notifyUser(
        "Validation Error",
        "Duplicate entries not allowed for family and stranger quiz.",
        "error"
      );
      return;
    }

    // If we get here, validation has passed
    setIsSaving(true);
    const htmlContent = questionRef.current.innerHTML;
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    
    const answers = [];
    const editableCellsForSubmit = document.querySelectorAll(
      '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
    );

    editableCellsForSubmit.forEach((cell) => {
      answers.push(cell.textContent.trim());
    });

    const payload = {
      course_id: lessonIdentifiers.course_id,
      quiz_ht_id: contentDetails.htmlCodeDetails.quiz_ht_id,
      question: contentDetails.questions[0].question_id,
      answer_text: htmlContent,
      completion_time: timeTakenInMinutes,
      answers: answers
    };

    const payloadArray = [payload];

    try {
      await saveQuizResponse(payloadArray);
      setQuizSubmitted(true);
      updateLessonCompletionData(content);
    } catch (error) {
      console.error("Error saving quiz:", error);
      notifyUser(
        "Error",
        "There was a problem saving your quiz. Please try again.",
        "error"
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleTimeUp = async () => {
    if (questionRef.current) {
      questionRef.current.innerHTML = "";
    }
  };

  // Check quiz submission and stat states before display check
  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return (
      <QuizResultCard
        data={modalData}
        title={quizStatApiResponse.quizStatDetails[0].MasterQuizHtmlQuiz.quiz_name}
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        status="Submitted"
      />
    );
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return (
      <QuizResultCard
        data={modalData}
        title={quizStatApiResponse.quizStatDetails[0].MasterQuizHtmlQuiz.quiz_name}
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
    );
  }

  // Main quiz content
  return display ? (
    <>
      {!quizStarted ? (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      ) : (
        <Box className="AFQ_wrapper">
          {contentDetails.htmlCodeDetails.estimated_completion_time && (
            <Box className="AFQ_wrapper__time">
              <QuizTimer 
                key={timerKey} 
                estimatedCompletionTime={contentDetails.htmlCodeDetails.estimated_completion_time} 
                handleComplete={handleTimeUp}
              />
            </Box>
          )}
          <Box
            ref={questionRef}
            className="AFQ_wrapper__long-question-wrapper"
            dangerouslySetInnerHTML={{
              __html: contentDetails.questions[0]?.question.replace(/&nbsp;/g, " ") || "",
            }}
          />
          <Box
            className="AFO_wrapper__answer-wrapper"
            dangerouslySetInnerHTML={{
              __html: contentDetails.htmlCodeDetails.html_question.replace(/&nbsp;/g, " ") || "",
            }}
          />
          <Box
            className="SAQ_wrapper-navigate"
            sx={{ display: "flex", justifyContent: "flex-end" , marginTop: "20px", paddingTop: "20px", }}
          >
            <Button
              className="SAQ_wrapper-navigate__button"
              variant="outlined"
              onClick={handleSave}
              disabled={isSaving}
            >
             {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      )}
    </>
  ) : (<></>);
};

export default HtmlCodeQuiz;