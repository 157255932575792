import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";
import downloadIcon from "assets/icons/downloadicon.png";
import { API_ENDPOINTS } from "config/ApiConfig";
import { handleDownload } from "utilityFunction/donwloadFile";
import UserContext from "context/userContext/UserContext";

const EnrolledCourseCard = ({
  courseName,
  level,
  image,
  status,
  progress,
  slug,
  certificate
}) => {
  const {userData } = useContext(UserContext);
  //navigation var
  const navigate = useNavigate();
  const [certificateUrl, setCertificateUrl] = useState("");
  const handleEnrollNow = () => {
    // Append the slug to the URL and navigate
    const courseDetailsUrl = `/app/course/${slug}/learn`;
    navigate(courseDetailsUrl);
  };

  useEffect(() => {
    if(certificate) {
      setCertificateUrl(`${API_ENDPOINTS.DOWNLOAD_CERTIFICATE}/${certificate}.pdf`);
    }
  },[certificate])
  const renderStatus = () => {
    switch (status) {
      case "in-progress":
        return (
          <>
            <Box
              sx={{
                width: "100%",
                bgcolor: "#F1FEFE",
                // mt: 2,
                position: "absolute",
                bottom: "0px",
                left: "0px",
                borderRadius: "0 0 13px 13px !important",
              }}
            >
              <Box
                sx={{
                  width: `${progress}%`,
                  bgcolor: "#39cccc",
                  height: 15,
                  borderRadius: progress === 100 ? "0 0 13px 13px !important" : "0 0 0 13px !important",
                }}
              />

            </Box>
            <Typography
              sx={{
                position: "absolute",
                color: "black",
                bottom: "0px",
                left: "24px",
                fontSize: "0.7rem",
              }}
            >
              {`${progress}%`}
            </Typography>
          </>
        );
      case "enrolled":
        return (
          <Button className="enrolled-course-card-button__start-learning text">
            Start Learning →
          </Button>
        );
      case "completed":
        return (
          userData && userData[0].is_institution_user === 1 ? (
            <Box className="enrolled-course-card-button__download-certificate-wrapper">
              <Button className="enrolled-course-card-button__download-certificate-wrapper--text">
              Congratulations!
              </Button>
            </Box>
          ) : (
            <Box className="enrolled-course-card-button__download-certificate-wrapper">
              <Button
                className="enrolled-course-card-button__download-certificate-wrapper--text"
                onClick={() => handleDownload(certificateUrl)}
              >
                Download Certificate
                <Box
                  component="img"
                  alt="download"
                  src={downloadIcon}
                  className="enrolled-course-card-button__download-certificate-wrapper--icon"
                />
              </Button>
            </Box>
          )
        );
        
      default:
        return null;
    }
  };

  return (
    <Box>
      <Card
        className={`enrolled-course-card ${progress
            ? "enrolled-course-card--with-progress"
            : "enrolled-course-card--without-progress"
          }`}
      >
        <Box onClick={handleEnrollNow} sx={{ cursor: "pointer" }} className="enrolled-course-card__wrapper">
          <CardMedia
            component="img"
            height="200"
            image={image}
            className="enrolled-course-card__card-media"
          />
          {/* <Typography className="enrolled-course-card__title text"></Typography> */}
        </Box>
        <CardContent sx={{ flexGrow: 1, paddingBottom: "4vh !important" }}>
          <Typography
            className="enrolled-course-card__course-name"
            variant="body1"
            onClick={handleEnrollNow}
          >
            {courseName}
          </Typography>
          {renderStatus()}
        </CardContent>
      </Card>
    </Box>
  );
};

export default EnrolledCourseCard;
