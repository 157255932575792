import React, { useState, useContext, useRef, useEffect } from "react";
  // import Plyr from "plyr-react";
  // import 'plyr-react/plyr.css';
import LessonContext from "context/lessonContext/LessonContext";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ReactPlayer from "react-player";
import UserContext from "context/userContext/UserContext";
import { BASE_URL, PRODUCTION_URL } from "config/ApiConfig";

const VideoPlayerYoutube = ({ videoUrl, thumbnailUrl, content }) => {
  const { updateLessonCompletionData } = useContext(LessonContext);
  const { userData } = useContext(UserContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isInstitutionUser, setIsInstitutionUser] = useState(false);
  const playerRef = useRef(null);
  const isProduction = BASE_URL === PRODUCTION_URL;
  const handleProgress = ({ played }) => {
    const watchPercentage = played * 100;

    const watchTime = isProduction ? '85' : '1'

    if (watchPercentage >= watchTime && !isCompleted) {
      updateLessonCompletionData(content); 
      setIsCompleted(true); // Mark as completed to prevent multiple calls
    }
  };

  useEffect(() => {
    if (userData && userData[0].is_institution_user === 1) {
      setIsInstitutionUser(true);
    } else {
      setIsInstitutionUser(false);
    }
  }, [userData]);

  return (
    <ReactPlayer
    ref={playerRef}
      className="react-player"
      url={`https://youtube.com/embed/${videoUrl}?rel=0&enablejsapi=1`}
      style={{ borderRadius: "15px" }}
      width="100%"
      height="100%"
      light={
        <img
          src={thumbnailUrl}
          alt="Thumbnail"
          className="react-player__preview--image"
        />
      }
      config={{
        youtube: {
          playerVars: {
            rel: 0,            // Disable related videos
            showinfo: 0,       // Hide video title and player actions
            modestbranding: 1,  // Hide YouTube logo
            controls: 0, // Disable controls to hide "Watch on YouTube"
          }
        }
      }}
      controls={true}
      muted={isInstitutionUser ? true : false}
      onProgress={handleProgress}
    />
  );
};

export default VideoPlayerYoutube;
