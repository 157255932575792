import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "components/common/navbar/Navbar";
import { Box, Container, Modal, Typography, Button } from "@mui/material";
import Footer from "components/common/footer/Footer";
import UserContext from "context/userContext/UserContext";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import LoadingContext from "context/loadingContext/LoadingContext";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { dateOfBirthFormSchema } from "config/schemas/formSchema";
import { decryptData, encryptData } from "utilityFunction/encryption&Decryption";
import usePostRequest from "hooks/usePostRequest";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePutRequest from "hooks/usePutRequest";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import Clarity from "@microsoft/clarity";

const AppLayout = () => {
  const { isAppLoading, setIsAppLoading } = useContext(LoadingContext);
  const { onLogin, userData } = useContext(UserContext);
  const { message, notifyUser, clearUserNotification } = useContext(NofityUserContext);
  const [datePickerModalOpen, setDatePickerModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formattedDOB, setFormattedDOB] = useState(null);
  const [countdown, setCountdown] = useState(6);
  const sixYearsAgoJanuary = dayjs().subtract(6, "year").startOf("year");

  // User Info Fetch
  const {
    data: UserIdInfo,
    loading: loadingUserInfo,
    error: userIdError,
    getData: UserIdPayload,
  } = useGetRequest(API_ENDPOINTS.GET_USER_INFO_BY_USER_ID);

  useEffect(() => {
    if (UserIdInfo) {
      setIsAppLoading(true);
      try {
        const decryptedUserInfo = {
          ...UserIdInfo,
          username: decryptData(UserIdInfo.username),
          email: decryptData(UserIdInfo.email),
          first_name: decryptData(UserIdInfo.first_name),
          last_name: decryptData(UserIdInfo.last_name),
          date_of_birth: UserIdInfo.date_of_birth
            ? decryptData(UserIdInfo.date_of_birth)
            : null,
        };
        if (decryptedUserInfo.date_of_birth === null || decryptedUserInfo.date_of_birth === "") {
          onLogin(decryptedUserInfo);
          setDatePickerModalOpen(true);
        } else {
          onLogin(decryptedUserInfo);
        }
         // Call Clarity identify once user info is available

        Clarity.identify(decryptedUserInfo.user_id, {
          email: decryptedUserInfo.email,
          username: decryptedUserInfo.username,
          first_name: decryptedUserInfo.first_name,
          last_name: decryptedUserInfo.last_name,
        });

      } catch (error) {
        console.error("Error decrypting user info:", error);
      } finally {
        setIsAppLoading(false);
      }
    } else if (userIdError) {
      console.error("Error fetching user info:", userIdError);
    }
  }, [UserIdInfo]);

  useEffect(() => {
    const checkTokenAndSetUserInfo = async () => {
  

      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        redirectToLogin();
        return;
      }

      try {
        const decodedToken = jwtDecode(storedToken);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp && decodedToken.exp < currentTime) {
          handleSessionExpiry();
        } else {
          axios.defaults.headers.common["Authorization"] = `Bearer ${storedToken.replace(/^["']|["']$/g, "")}`;
          await fetchUserIdInfo();
        }
      } catch (err) {
        redirectToLogin();
      }
    };

    const fetchUserIdInfo = async () => {
      try {
        await UserIdPayload();
      } catch (error) {
        redirectToLogin();
      }
    };

    const redirectToLogin = () => {
      setModalOpen(false);
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    };

    const handleSessionExpiry = () => {
      setModalOpen(true);
      localStorage.clear();
      let timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            redirectToLogin();
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    };

    checkTokenAndSetUserInfo();
  }, []);

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: { dateOfBirth: null },
    validationSchema: dateOfBirthFormSchema,
    onSubmit: async (values, actions) => {
      setIsAppLoading(true);
      if (values.dateOfBirth) {
        setDatePickerModalOpen(false);
        const formattedValues = {
          dateOfBirth: values.dateOfBirth
            ? dayjs(values.dateOfBirth).format("DD-MM-YYYY").toString()
            : null,
        };
        try {
          const encryptedDOB = encryptData(formattedValues.dateOfBirth);
          await updateUserData({ date_of_birth: encryptedDOB });
          setFormattedDOB(formattedValues.dateOfBirth);
        } catch (err) {
          notifyUser("Error updating User Info", "Issue with updating info", "error");
        } finally {
          setIsAppLoading(false);
        }
      }
    },
  });

  const {
    data: updatedUserInfo,
    loading: loadingUpdatedUserInfo,
    error: updateUserError,
    putData: updateUserData,
  } = usePutRequest(API_ENDPOINTS.EDIT_USER_INFO_AND_SAVE);

  useEffect(() => {
    if (!loadingUpdatedUserInfo && updatedUserInfo) {
      setIsAppLoading(true);
      const updatedUserObject = userData.find(
        (item) => item && typeof item === "object" && "user_id" in item
      );
      if (updatedUserObject) {
        updatedUserObject.date_of_birth = formattedDOB;
        onLogin(updatedUserObject);
        notifyUser("Date Added", "Date of Birth has been added", "success");
        setIsAppLoading(false);
      }
    }
  }, [formattedDOB]);

  return (
    <Container maxWidth={false} className="app-layout__container">
      <Box className="app-layout-navbar">
        <Navbar />
      </Box>
      <Box className="page-body" mt={4}>
        <Outlet />
      </Box>
      <Footer />
      {/* Date Picker Modal */}
      <Modal
        open={datePickerModalOpen}
        onClose={() => {}}
        disableEscapeKeyDown
        aria-labelledby="date-picker-modal-title"
        aria-describedby="date-picker-modal-description"
      >
        <Box
          className="session-expiry-modal"
          component="form"
          noValidate
          onSubmit={handleSubmit}
          id="datepicker"
        >
          <Typography
            className="session-expiry-modal__heading"
            variant="h6"
            component="h2"
          >
            Please select your date of birth
          </Typography>
          <Box className="session-expiry-modal__datepicker-wrapper">
            <Box className="session-expiry-modal__datepicker-wrapper--datepicker">
              <DatePicker
                format="DD/MM/YYYY"
                referenceDate={sixYearsAgoJanuary}
                views={["year", "month", "day"]}
                maxDate={dayjs().subtract(6, "year").startOf("day")}
                value={values.dateOfBirth}
                onChange={(value) => {
                  setFieldValue("dateOfBirth", value);
                  setFieldTouched("dateOfBirth", true, false);
                }}
                onBlur={() => setFieldTouched("dateOfBirth", true)}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    required: true,
                    error: touched.dateOfBirth && Boolean(errors.dateOfBirth),
                    InputProps: {
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                    },
                  },
                }}
              />
              {errors.dateOfBirth && touched.dateOfBirth ? (
                <Typography className="error-input_login">
                  {errors.dateOfBirth}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Button
              className="session-expiry-modal__datepicker-wrapper--button"
              type="submit"
              disabled={isSubmitting}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <Box className="session-expiry-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Session Expired
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your session has expired. Redirecting to login page in {countdown}{" "}
            seconds
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default AppLayout;
