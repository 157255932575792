import { createContext, useState } from "react";

const CourseDetailsContext = createContext();

export default CourseDetailsContext;

export const CourseDetailsProvider = ({ children }) => {
  const [courseSlug, setCourseSlug] = useState("");
  const [courseInformation, setCourseInformation] = useState({});
  const [courseDiscountPrice, setCourseDsicountPrie] = useState(0);
  const [loadingCourseInformation, setLoadingCourseInformation] =
    useState(true);

  const addDataTOCourseContext = (data) => {
    setCourseInformation(data);
    setLoadingCourseInformation(false);
  };

  const clearDataFromCourseContext = () => {
    setCourseInformation({});
    setLoadingCourseInformation(true);
  };
  return (
    <CourseDetailsContext.Provider
      value={{
        loadingCourseInformation,
        courseInformation,
        addDataTOCourseContext,
        clearDataFromCourseContext,
        setLoadingCourseInformation,
        courseSlug,
        setCourseSlug,
        courseDiscountPrice,
        setCourseDsicountPrie
      }}
    >
      {children}
    </CourseDetailsContext.Provider>
  );
};
