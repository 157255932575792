import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { IoIosNotificationsOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { GoDotFill } from "react-icons/go";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { decryptData } from "utilityFunction/encryption&Decryption";
import usePostRequest from "hooks/usePostRequest";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";

const NotificationWindow = () => {
    const [isLoading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [areAllRead, setAreAllRead] = useState(false);
    const navigate = useNavigate();

    const {
        data: notificationInfo,
        loading: loadingNotificationInfo,
        error: notificationInfoError,
        getData: NotificationInfoPayload,
    } = useGetRequest(API_ENDPOINTS.GET_NOTIFICATION_INFO);

    const { postData: markNotificationsRead } = usePostRequest(API_ENDPOINTS.MARK_NOTIFICATION_INFO);
    const { postData: markNotificationsClicked } = usePostRequest(API_ENDPOINTS.CLICK_NOTIFICATION_INFO);

    useEffect(() => {
        if (!isLoading) {
            fetchNotificationInfo();
            setLoading(true);
        }
    }, [isLoading]);

    useEffect(() => {
        if (!loadingNotificationInfo && notificationInfo) {
            const sortedNotifications = [...notificationInfo].sort((a, b) =>
                new Date(b.added_on) - new Date(a.added_on)
            );
            setNotifications(sortedNotifications);
        }
    }, [loadingNotificationInfo, notificationInfo]);

    const unreadCount = notifications.filter(notification => notification.is_read === 0).length;

    const handleToggle = async () => {
        setIsVisible(!isVisible);
        if (!areAllRead && unreadCount > 0) {
            await markAllAsRead();
        }
    };

    const markAllAsRead = async () => {
        try {
            const unreadIds = notifications
                .filter(notification => notification.is_read === 0)
                .map(notification => notification.user_ntf_id);

            if (unreadIds.length > 0) {
                await markNotificationsRead({ ids: unreadIds });
                setNotifications(notifications.map(notification => ({
                    ...notification,
                    is_read: 1,
                })));
                setAreAllRead(true);
            }
        } catch (error) {
            console.error("Failed to mark notifications as read:", error);
        }
    };

    const markAllAsClicked = async () => {
        try {
            const unreadIds = notifications
                .filter(notification => notification.is_clicked === 0)
                .map(notification => notification.user_ntf_id);

            if (unreadIds.length > 0) {
                await markNotificationsClicked({ ids: unreadIds });
                setNotifications(notifications.map(notification => ({
                    ...notification,
                    is_clicked: 1
                })));
                setAreAllRead(true);
            }
        } catch (error) {
            console.error("Failed to mark notifications as read:", error);
        }
    };

    const handleNotificationClick = async (id) => {
        try {
            await markNotificationsClicked({ ids: [id] });
            setNotifications(notifications.map(notification =>
                notification.user_ntf_id === id
                    ? { ...notification, is_clicked: 1 }
                    : notification
            ));
        } catch (error) {
            console.error("Failed to mark notification as clicked:", error);
        }
    };

    const fetchNotificationInfo = async () => {
        try {
            await NotificationInfoPayload();
        } catch (error) {
            console.error("Failed to fetch notification info:", error);
        }
    };

    const unclickedCount = notifications.filter(notification => notification.is_clicked === 0).length;

    return (
        <>
            <Button className="courses text" onClick={handleToggle}>
                <div className="notification_wrapper-icon-wrapper">
                    <IoIosNotificationsOutline size={24} />
                    {unreadCount > 0 && (
                        <span className="notification_wrapper--unread-count">{unreadCount}</span>
                    )}
                </div>
            </Button>

            {isVisible && (
                <Box className="notification_wrapper">
                    <Box className="notification_wrapper__bar">
                        <h5>Notifications</h5>
                        <Box className="notification_wrapper__buttons">
                            {!areAllRead && (
                                <Typography variant="text" onClick={markAllAsClicked}>
                                    {unclickedCount > 0 && <GoDotFill className="notification_wrapper__green" />} Mark as Read
                                </Typography>
                            )}
                        </Box>
                        <RxCross2 onClick={handleToggle} className="notification_wrapper__close" />
                    </Box>
                    
                    {notifications.length === 0 ? (
                   <Box 
                   className="notification_wrapper__empty" 
                  sx={{ 
                 display: 'flex', 
                 justifyContent: 'center', 
                 alignItems: 'center', 
                 padding: '20px', 
                 textAlign: 'center' 
                   }}
                >
                 <Typography variant="body1" color="textSecondary">
                 No notifications
                </Typography>
                </Box>
                    ) : (
                        notifications.map(notification => {
                            const firstName = decryptData(notification.User.first_name);
                            const lastName = decryptData(notification.User.last_name);
                            const initial = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

                            const handleRedirect = (url) => {
                                if (url.startsWith('http://') || url.startsWith('https://')) {
                                    // External link
                                    window.location.href = url;
                                } else {
                                    // Internal navigation
                                    navigate(url);
                                }
                            };
                            
                            return (
                                <Box
                                    key={notification.user_ntf_id}
                                    className="notification_wrapper__message"
                                    onClick={() => handleRedirect(notification.redirect_url)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Box className="notification_wrapper__avatar">
                                        {initial}
                                    </Box>
                                    <Box className="notification_wrapper__content">
                                        <Typography className="notification_wrapper__content__name">
                                            {decryptData(notification.User.first_name)} {decryptData(notification.User.last_name)}
                                            <span className="notification_wrapper__content_time">
                                                &nbsp;{ formatDistanceToNow(new Date(notification.added_on), { addSuffix: true })}
                                            </span>
                                        </Typography>
                                        <Typography className="notification_wrapper__content-feedback">
                                            {notification.message}
                                        </Typography>
                                    </Box>
                                    {notification.is_clicked === 0 && <GoDotFill className="notification_wrapper__green" />}
                                </Box>
                            );
                        })
                    )}
                </Box>
            )}
        </>
    );
};

export default NotificationWindow;