import CourseDetailsPage from "pages/appPage/courseDetailsPage/CourseDetailsPage";
import VerifyCertificatePage from "pages/publicPage/payment/Payment";
import Courses from "pages/publicPage/Course";
import CoursePaymentPage from "pages/publicPage/purchase/CoursePaymentPage";
import PaymentSuccessPage from "pages/appPage/paymentPages/PaymentSuccessPage";

export const publicRoutes = [
  
  {
    path: "courses",
    element: <Courses />,
  },
  {
    path: "view-course/:slug",
    element: <CourseDetailsPage />,
  },
  {
    path: "verify-certificate",
    element: <VerifyCertificatePage />,
  },
  {
    path: "purchase-course/:slug",
    element: <CoursePaymentPage />,
  },
  {
    path: "payment/success/:paymentId",
    element: <PaymentSuccessPage />,
  },
];
