import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import DisciplineCard from "components/module/app/dashboard/DisciplineCard";
import ProgressBar from "components/module/app/dashboard/ProgressBar";
import { formatDuration } from "utilityFunction/convertMinToHours";
import barchart from "assets/icons/bar-chart.svg";
import clock from "assets/icons/clock2.svg";
import blueStar from "assets/icons/star-blue.svg";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import UserContext from "context/userContext/UserContext";
import DashboardQuizCard from "components/module/app/dashboard/DashboardQuizCard";
import { DashboardCardSkeleton } from "components/common/skeleton/SkeletonCard";

const CourseCard = () => {
  //user context
  const { userData, isLoading } = useContext(UserContext);

  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({});
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showQuizCard, setShowQuizCard] = useState(false);
  const [showCourseCard, setShowCourseCard] = useState(false);

  // custom get req hook
  const {
    data: quizCourseData,
    loading: loadingQuizCourse,
    getData: quizCoursePayload,
  } = useGetRequest(API_ENDPOINTS.COURSE_BASELINE_ASSESSMENT);

  const {
    data: prevCourseData,
    loading: loadingPrevCourse,
    getData: prevCoursePayload,
  } = useGetRequest(API_ENDPOINTS.LAST_ACCESSED_LESSON);

  useEffect(() => {
    const fetchLatestCourses = async () => {
      if (!isLoading) {
        try {
          await quizCoursePayload();
          await prevCoursePayload();
        } catch (err) {
          console.log("error:", err);
        }
      }
    };
    fetchLatestCourses();
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      // First check quiz data
      if (!loadingQuizCourse && quizCourseData) {
        if (Array.isArray(quizCourseData.takeAssessment) && quizCourseData.takeAssessment.length > 0) {
          setQuizData(quizCourseData);
          setShowQuizCard(true);
        } else {
          setShowQuizCard(false);
          setQuizData(null);
        }
      }
      
      // Then check course data
      if (!loadingPrevCourse && prevCourseData) {
        if (prevCourseData.previousCourse) {
          setCourseData(prevCourseData);
          setShowCourseCard(true);
        } else {
          setShowCourseCard(false);
          setCourseData({});
        }
      }
      
      if (!loadingQuizCourse && !loadingPrevCourse) {
        setLoading(false);
      }
    }
  }, [isLoading, quizCourseData, loadingQuizCourse, prevCourseData, loadingPrevCourse]);

  useEffect(() => {
    // Set a timeout to stop showing the skeleton after a while
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout duration as needed (e.g., 3 seconds)

    return () => clearTimeout(timer); // Clean up the timeout
  }, []);

  const handleLessonNavigation = () => {
    navigate(
      `/app/course/${courseData.previousCourse.continueLearning.MasterCourse.slug}/learn/${courseData.previousCourse.continueLearning.MasterLesson.lesson_slug}`
    );
  };

  if (loading) {
    return (
      <Box sx={{ marginBottom: "2rem" }}>
        <DashboardCardSkeleton />
      </Box>
    );
  }

  return (
    <Box className="dashboard-cards-container">
      {/* First render Quiz Card if available */}
      {showQuizCard && (
        <Box sx={{ marginBottom: showCourseCard ? "2rem" : 0 }}>
          <DashboardQuizCard data={quizData} />
        </Box>
      )}

      {/* Then render Course Card if available */}
      {showCourseCard && (
        <Box onClick={handleLessonNavigation} sx={{ cursor: "pointer" }}>
          <Card className="dashboard-card">
            <CardContent className="dashboard-card-content">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className="dashboard-card-content__title"
                    variant="h5"
                  >
                    {courseData?.previousCourse?.continueLearning?.MasterCourse
                      ?.course_name || ""}
                  </Typography>
                </Grid>

                <Grid item xs={12} className="padding-top-0">
                  <Box className="dashboard-card-content__info">
                    <Box
                      className="dashboard-card-content__text-icon-wrapper"
                      mr={3}
                    >
                      <Box
                        component="img"
                        src={blueStar}
                        className="dashboard-card-content__icon"
                        id="star"
                      />
                      <Typography
                        className="dashboard-card-content__text"
                        variant="body1"
                      >
                        {courseData?.previousCourse?.avgReview?.avg_review || ""}
                      </Typography>
                    </Box>
                    <Box
                      className="dashboard-card-content__text-icon-wrapper"
                      mr={3}
                    >
                      <Box
                        component="img"
                        src={barchart}
                        className="dashboard-card-content__icon"
                      />
                      <Typography
                        variant="body1"
                        className="dashboard-card-content__text"
                      >
                        {courseData?.previousCourse?.continueLearning?.MasterCourse
                          ?.RefCourseDifficulty?.name || ""}
                      </Typography>
                    </Box>
                    <Box className="dashboard-card-content__text-icon-wrapper">
                      <Box
                        component="img"
                        src={clock}
                        className="dashboard-card-content__icon"
                        id="clock"
                      />
                      <Typography
                        variant="body1"
                        className="dashboard-card-content__text"
                      >
                        {formatDuration(
                          courseData?.previousCourse?.continueLearning?.MasterCourse
                            ?.duration
                        ) || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    mt={4}
                    variant="body2"
                    mb={4}
                    className="dashboard-card-content__description"
                  >
                    {courseData?.previousCourse?.continueLearning?.MasterCourse
                      ?.description || ""}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <DisciplineCard
                    lessonData={courseData?.previousCourse?.continueLearning || ""}
                  />
                </Grid>

                <Grid item xs={12} mt={2} mb={2}>
                  <Button
                    className="dashboard-card__button"
                    variant="contained"
                    onClick={handleLessonNavigation}
                  >
                    Continue Learning
                  </Button>
                </Grid>
                {courseData?.previousCourse?.courseStatus?.status === 'in-progress' && (
                  <Grid item xs={12}>
                    <ProgressBar
                      hoursRemaining={
                        courseData?.previousCourse?.lessonProgress.leftOutMinutes ||
                        ""
                      }
                      totalHours={
                        courseData?.previousCourse?.lessonProgress.totalMinutes || ""
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default CourseCard;