import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { PDFReader } from "components/module/app/courseChapter/PDFReader";
import LessonContext from "context/lessonContext/LessonContext";
import VideoPlayerSkeleton from "components/common/skeleton/videoPlayerSkeleton";
import usePostRequest from "hooks/usePostRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import ContextMenu from "./ContextMenu";
import NofityUserContext from 'context/notifyUserContext/NotifyUserContext';

const PDFComp = ({ content }) => {
  const { lessonInfo, lessonIdentifiers, updateLessonCompletionData } = useContext(LessonContext);
  const [pdfFileName, setPdfFileName] = useState("");
  const [contentDetails, setContentDetails] = useState(null);
  const [contextMenu, setContextMenu] = useState(null); // State for custom context menu
  const { notifyUser } = useContext(NofityUserContext);

  const { data: pinComponent, postData: pinContent } =
    usePostRequest(API_ENDPOINTS.POST_PIN_LESSON_QUIZ);

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find((item) => item.content_id === content);

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
      }
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails) {
      const fileName = String(contentDetails.MasterPDF.name);
      setPdfFileName(fileName);
    }
  }, [contentDetails]);

  useEffect(() => {
    if (content) {
      updateLessonCompletionData(content);
    }
  }, [content, updateLessonCompletionData]);

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu
    setContextMenu({
      mouseX: event.clientX + 0,
      mouseY: event.clientY - 0,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handlePinComponent = async () => {
    console.log("Component pinned!");
    await pinContent({ course_id: lessonIdentifiers?.course_id, content_id: content });
    notifyUser(
      "Component pinned successfully",
       "",
      "success"
    );

    handleCloseContextMenu();
  };

  const handleOpenDictionary = () => {
    console.log("Dictionary opened!");
    handleCloseContextMenu();
  };

  return (
    <Box
      className="pdf-component"
      onContextMenu={handleContextMenu} // Attach custom context menu handler
    >
      {pdfFileName ? (
        <PDFReader pdf={pdfFileName} content={content} />
      ) : (
        <VideoPlayerSkeleton />
      )}

<ContextMenu
  contextMenu={contextMenu}
  onClose={handleCloseContextMenu}
  onPin={handlePinComponent}
  onDictionary={handleOpenDictionary}
  menuType="pdf"
/>
    </Box>
  );
};

export default PDFComp;
