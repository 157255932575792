import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import AvailableCourseCard from "components/module/app/myCourses/AvailableCourseCard";
import { formatDuration } from "utilityFunction/convertMinToHours";
import { API_ENDPOINTS } from "config/ApiConfig";
import { useLocation } from "react-router-dom";

// const availableCoursesData = [
//   {
//     id: 1,
//     courseName: "COURSE NAME",
//     level: "Level One",
//     image: image,
//     rating: 4.8,
//     duration: 20,
//     description:
//       "Learn the basic principle of Life Skills through MTS Interactive Course",
//   },
// ];

const AvailableCourses = ({ coursesText, availableCoursesDetails }) => {

  const location = useLocation();
  const [referralCode, setReferralCode] = useState(null);

  // Sorting courses by the numeric value extracted from course_name
  const sortedCourses = [...availableCoursesDetails].sort((a, b) => {
    const numA = parseInt(a.course_name.match(/\d+/)?.[0], 10);
    const numB = parseInt(b.course_name.match(/\d+/)?.[0], 10);
    return numA - numB;
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('referral');

    if (code) {
      setReferralCode(code);

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);

      const referralData = {
        code,
        expiry: expirationDate.getTime(),
      };

      localStorage.setItem('referralCode', JSON.stringify(referralData));
    } else {
      const storedData = localStorage.getItem('referralCode');
      if (storedData) {
        const { code: storedCode, expiry } = JSON.parse(storedData);
        if (new Date().getTime() < expiry) {
          setReferralCode(storedCode);
        } else {
          localStorage.removeItem('referralCode');
        }
      }
    }
  }, [location.search]);

  return (
    <Box>
      <Typography
        component="p"
        className="enrolled-courses-main-title"
        id="avalaible"
      >
        {coursesText}
      </Typography>
      <Grid container columnSpacing={6} rowSpacing={4}>
        {sortedCourses.map((course) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={course.course_id}
            sx={{ mb: "3vh" }}
          >
            <AvailableCourseCard
              courseName={course.course_name}
              level={`Level ${course.difficulty}`}
              image={`${API_ENDPOINTS.GET_IMAGE}/${course.MasterImage.name}`}
              rating={course?.avg_review || 0}
              duration={formatDuration(course.duration)}
              description={course.description}
              slug={`${course.slug}${referralCode ? `?referral=${referralCode}` : ''}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AvailableCourses;
