import React, { useContext, useEffect, useRef, useState } from "react";
import CourseViewContext from "context/courseViewContext/CourseViewContext";
import LoadingContext from "context/loadingContext/LoadingContext";
import { Box, Grid } from "@mui/material";
import DisciplineInSchoolCard from "components/module/app/courseView/DisciplineInSchoolCard";
import CourseProgressCircle from "components/common/progress/CircularProgress";
import ActivityAnalyticsCard from "components/module/app/courseView/ActivityAnalyticsCard";
import EngageCard from "components/module/app/courseView/EngageCard";
import CourseOutline from "components/module/app/courseView/CourseOutline";
import { formatDuration } from "utilityFunction/convertMinToHours";
import LoadingOverlay from "overlay/loadingOverlay";
import usePostRequest from "hooks/usePostRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { useParams } from "react-router-dom";
import useGetRequest from "hooks/useGetRequest";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { handleDownload } from "utilityFunction/donwloadFile";
import UserContext from "context/userContext/UserContext";


const CourseViewPage = () => {

  const { transformedData, totalPercentage } = useContext(CourseViewContext); // total percentage here from context API
  const { message, notifyUser } = useContext(NofityUserContext);
  const { isAppLoading, setIsAppLoading } = useContext(LoadingContext);
  const { userData } = useContext(UserContext);
  const { slug } = useParams();
  
  console.log(totalPercentage)

  const {
    data: createCertificate,
    error: createCertificaterError,
    loading: loadingcreateCertificate,
    postData: postCreateCertificate,
  } = usePostRequest(API_ENDPOINTS.CREATE_COURSE_COMPLETITION_CERTIFICATE);

  const {
    data: certificateData,
    loading: loadingCertificateData,
    error: certificateDataError,
    getData: certificateDataPayload,
  } = useGetRequest(API_ENDPOINTS.COURSE_CERTIFICATE_DETAILS);

  // State to store context data
  const [percentage, setPercentage] = useState("");
  const [totalLesson, setTotalLesson] = useState("");
  const [completedLesson, setCompletedLesson] = useState("");
  const [timeSpent, setTimeSpent] = useState("");
  const [certificateUrl, setCertificateUrl] = useState("");
  const [certificateDetails, setCertificateData] = useState("");
  const [hasFetched, setHasFetched] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (transformedData || totalPercentage) {
      // Update state with fetched data
      if (typeof totalPercentage === 'number') {
        setPercentage(totalPercentage.toFixed(0));
      }

      if (transformedData.totalLessons) {
        setTotalLesson(transformedData.totalLessons);
      }

      if (transformedData.timeSpent) {
        setTimeSpent(formatDuration(transformedData.timeSpent));
      }

      if (transformedData.completedLessons) {
        setCompletedLesson(transformedData.completedLessons);
      }

    }
  }, [transformedData, totalPercentage, setIsAppLoading]);

  const generateCertificate = async () => {
    try {
      const payLoad = {
        slug: slug
      }
      setIsGenerating(true);
      await postCreateCertificate(payLoad);
      setIsGenerating(false);
      fetchedRef.current = false;  // Mark the API as called
      setHasFetched(false);

    } catch (error) {
      console.error("Error fetching certificate data:", error);
    }
  };

  useEffect(() => {
    if (loadingcreateCertificate && !createCertificate) {
      notifyUser("Hang tight!", "Your certificate is being generated. Please give us a moment.", "success");
      return;
    }
  })

  const fetchedRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!fetchedRef.current && percentage === '100') {
          await certificateDataPayload(slug);
          fetchedRef.current = true;  // Mark the API as called
          setHasFetched(true);  // Update state (optional)
        }
      } catch (error) {
        console.error("Error fetching certificate data:", error);
      }
    };

    if (percentage === '100' && !fetchedRef.current) {
      fetchData();
    }
  }, [percentage, slug, certificateDataPayload]);

  useEffect(() => {
    if (!loadingCertificateData && certificateData) {
      setHasFetched(false);
      setCertificateData(certificateData);

      if (certificateData.courseCertificate && Object.keys(certificateData.courseCertificate).length) {
        setCertificateUrl(`${API_ENDPOINTS.DOWNLOAD_CERTIFICATE}/${certificateData.courseCertificate.certificate_code}.pdf`)
      }
    }
  }, [certificateData, loadingCertificateData]);

  return (
    <Grid
      container
      spacing={6}
      sx={{
        paddingLeft: "2vh",
        paddingTop: "1vh",
      }}
    >
      <Grid item xs={12}>
        <DisciplineInSchoolCard certificateUrl={certificateUrl} />
      </Grid>
      {<Grid item xs={12}>
        <EngageCard />
      </Grid>}
      <Grid item xs={12} sm={6}>
        <CourseOutline />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className="course-view-component-wrapper">
          {percentage >= 0 && (
            <CourseProgressCircle
              finalProgress={percentage}
              size={200}
              thickness={4}
            />
          )}
          {percentage === '100' && (
            userData[0].is_institution_user === 1 ? null : (
              certificateDetails.courseCertificate &&
                Object.keys(certificateDetails.courseCertificate).length > 0 ? (
                <button
                  variant="outlined"
                  className="payment__button"
                  onClick={() => handleDownload(certificateUrl)}
                  disabled={isGenerating} // Optional: Disable while generating
                >
                  Download
                </button>
              ) : (
                <button
                  variant="outlined"
                  className="payment__button"
                  onClick={generateCertificate}
                  disabled={isGenerating} // Optional: Disable while generating
                >
                  {isGenerating ? "Generating..." : "Generate Certificate"}
                </button>
              )
            )
          )}


          {completedLesson > 0 && (
            <ActivityAnalyticsCard
              totalLesson={totalLesson}
              activitiesCompleted={completedLesson}
              duration={timeSpent}
            />
          )}
        </Box>
      </Grid>
      {/* <Grid item xs={12}>
        <CourseActivityStats />
      </Grid> */}
    </Grid>
  );
};

export default CourseViewPage;
