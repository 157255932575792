import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import EnrolledCourseCard from "components/module/app/myCourses/EnrolledCourseCard";
import { API_ENDPOINTS } from "config/ApiConfig";

const EnrolledCourses = ({ enrolledCoursesDetails }) => {
 
  const sortedCourses = enrolledCoursesDetails.sort((a, b) => {
    const gradeA = a.MasterCourse.course_name ? parseInt(a.MasterCourse.course_name.split('-')[1].trim()) : Infinity;
    const gradeB = b.MasterCourse.course_name ? parseInt(b.MasterCourse.course_name.split('-')[1].trim()) : Infinity;
    return gradeA - gradeB; 
  });

  console.log(enrolledCoursesDetails)
 
  return (
    <Box>
      <Typography
        component="p"
        className="enrolled-courses-main-title text"
        mb={2}
      >
        Enrolled Courses
      </Typography>
      <Grid container columnSpacing={1} rowSpacing={4}>
        {sortedCourses.map((course) => (     
          <Grid item xs={12} sm={6} md={3} key={course.course_id}>
            <EnrolledCourseCard
              courseName={course.MasterCourse.course_name}
              level={course.MasterCourse.course_name || "Unknown name"} 
              image={`${API_ENDPOINTS.GET_IMAGE}/${course.MasterCourse.MasterImage.name}`}
              status={course.status}
              progress={course.progress || 0}
              slug={course.MasterCourse.slug}
              certificate={course.certificateCode}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EnrolledCourses;