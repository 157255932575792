import { useEffect } from "react";
import Clarity from '@microsoft/clarity';
import { BASE_URL, PRODUCTION_URL } from "config/ApiConfig";
const isProduction = BASE_URL === PRODUCTION_URL;
const useClarity = () => {
  useEffect(() => {
    if (isProduction) {
      Clarity.init("qcrcmyain0");  
    }
  }, [isProduction]);
};

export default useClarity;
