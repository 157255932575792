import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingContext from "context/loadingContext/LoadingContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import useSortedCourseData from "hooks/useSortedCourseData";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import CourseProgressCircle from "components/common/progress/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import checkCircle from "assets/icons/check-circle-completed.svg";
import transparentCircle from "assets/icons/blank-circle.svg";
import CourseViewContext from "context/courseViewContext/CourseViewContext";
// import SkeletonList from "components/common/skeleton/SkeletonCard";

const CourseOutline = () => {
  //store the slug in a var
  const { slug } = useParams();
  //state to store the slug
  const [storeSlug, setStoreSlug] = useState("");

  //store the course chapters details
  const [courseChapter, setCourseChapter] = useState([]);

  //course view context
  const { transformData, setTotalPercentage ,resetState} = useContext(CourseViewContext);
  //loading global context
  const { isAppLoading } = useContext(LoadingContext);

  useEffect(() => {
    resetState();
  }, []); // Empty dependency array ensures it's called only once
  

  //store the slub in a usestate
  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
    }
  }, [slug]);

  //trigger the get req for the chapters timeline
  const {
    data: chapterData,
    error: chapterError,
    loading: loadingChapter,
    getData: chapterPayload,
  } = useGetRequest(API_ENDPOINTS.USER_COURSE_CHAPTER);

  useEffect(() => {
    const fetchChapter = async () => {
      if (!isAppLoading && storeSlug) {
        try {
          await chapterPayload(storeSlug);
        } catch (err) {
          // console.log("error with the get request api call");
        }
      }
    };
    fetchChapter();
  }, [isAppLoading, storeSlug]);

  useEffect(() => {
    if (!loadingChapter) {
      if (chapterError) {
        // console.log("error fetching the chapter error:", chapterError);
      } else if (chapterData) {
        //  Convert chapterData object to an array
        setCourseChapter(Object.values(chapterData));
      }
    }
  }, [chapterData, loadingChapter, chapterError]);

  // console.log(" course chapter in the cirriculum card", courseChapter);

  // Always call the hook, but handle when data is not available
  const {
    sortedCourseData: sortedChapter,
    totalLessons,
    completedLessons,
    completionPercentage,
    totalTimeSpent,
  } = useSortedCourseData(courseChapter);

  console.log(completionPercentage)

  useEffect(() => {
    if (sortedChapter && completionPercentage) {
      // console.log(
      //   "sorted course chapter in the curriculum card:",
      //   sortedChapter
      // );
      // console.log(
      //   "completion course chapter in the curriculum card:",
      //   completionPercentage
      // );
      // store the percentage in the context
      setTotalPercentage(completionPercentage);
      // Pass all data values to the transformData function
    transformData({
      sortedChapter,
      totalLessons,
      completedLessons,
      completionPercentage,
      totalTimeSpent,
    });
      // console.log("data sent to tranform data function");
    }
  }, [sortedChapter, completionPercentage]);

  return sortedChapter && sortedChapter.length > 0 ? (
    <Box className="course-outline-wrapper">
      {sortedChapter.map((chapter) => {
        const allLessonsCompleted = chapter.lessons.every(
          (lesson) => lesson.isCompleted
        );
        const completedLessonsCount = chapter.lessons.filter(
          (lesson) => lesson.isCompleted
        ).length;
        const progressPercentage =
          chapter.lessons.length > 0
            ? (completedLessonsCount / chapter.lessons.length) * 100
            : 0;

        return (
          <Accordion
            key={chapter.chapter_id}
            className="course-outline-wrapper__accordian"
          >
            <AccordionSummary
              className="course-outline-wrapper__accordian--summary"
              id={`panel${chapter.chapter_id}-header`}
              aria-controls={`panel${chapter.chapter_id}-content`}
              expandIcon={<ExpandMoreIcon />}
            >
              {chapter.isActive ? (
                <CourseProgressCircle
                  finalProgress={progressPercentage.toFixed(0)}
                  size={32.5}
                  thickness={4}
                  timeline={"timeline"}
                  timelinepercentage={"timelinepercentage"}
                />
              ) : (
                <Box
                  component="img"
                  src={allLessonsCompleted ? checkCircle : transparentCircle}
                  id={allLessonsCompleted ? "" : "transparentcircle"}
                  alt="completed-icon"
                  className="course-outline-wrapper__circle-icon"
                />
              )}
              <Typography
                className="course-outline-wrapper__title"
                variant="body2"
              >
                {chapter.chapter_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Timeline
                className="course-outline-timeline"
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {chapter.lessons.map((lesson, index) => (
                  <TimelineItem
                    key={lesson.lesson_id}
                    className="course-outline-timeline__item"
                  >
                    <TimelineSeparator>
                      <Box
                        component="img"
                        src={
                          lesson.isCompleted ? checkCircle : transparentCircle
                        }
                        id={lesson.isCompleted ? "" : "transparent-circle"}
                        alt="lesson-status-icon"
                        className={`course-outline-wrapper__circle-icon-list ${
                          lesson.isActive ? "active-lesson" : ""
                        }`}
                      />
                      {/* make the timeline seperator dissappear for the last child */}
                      {index < chapter.lessons.length - 1 && (
                        <TimelineConnector className="course-outline-timeline__item--connector" />
                      )}
                    </TimelineSeparator>
                    <TimelineContent className="course-outline-timeline__item--content">
                      <Box
                        className="timeline-content-wrapper"
                        id="course-outline"
                      >
                        {/* <Link
                          to={`/app/course/${storeSlug}/learn/${lesson.lesson_name
                            .toLowerCase()
                            .replace(/ /g, "-")}`}
                          className="timeline-content-wrapper__link"
                        >
                          <Typography className="timeline-content-wrapper__text">
                            {lesson.lesson_name}
                          </Typography>
                        </Link> */}
                        {lesson.isLocked ? (
                          <Typography className="timeline-content-wrapper__text locked">
                            {lesson.lesson_name}
                          </Typography>
                        ) : (
                          <Link
                            to={`/app/course/${storeSlug}/learn/${lesson.lesson_slug}`}
                            className="timeline-content-wrapper__link"
                          >
                            <Typography
                              className="timeline-content-wrapper__text"
                              id="cursor"
                            >
                              {lesson.lesson_name}
                            </Typography>
                          </Link>
                        )}
                        <Typography
                          variant="body2"
                          className="timeline-content-wrapper__duration"
                        >
                          {lesson.estimated_completion_time} min
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  ): '';
};

export default CourseOutline;
