import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Card, CardContent, Grid, Typography, IconButton } from "@mui/material";
import quiz from "assets/images/quiz.svg";
import chevronRight from "assets/icons/chevron-right.svg";
import book from "assets/icons/book.svg";
import { formatDuration } from "utilityFunction/convertMinToHours";
import barchart from "assets/icons/bar-chart.svg";
import clock from "assets/icons/clock2.svg";
import blueStar from "assets/icons/star-blue.svg";

const DashboardQuizCard = ({ data }) => {
  // Only render if we have data
  if (!data || !data.takeAssessment || !data.takeAssessment.length) {
    return null;
  }

  return (
    <Box className="dashboard-quiz-container">
      {data.takeAssessment.map((assessment, index) => {
        const hasLesson =
          assessment.groupedChapterDetails &&
          assessment.groupedChapterDetails[0] &&
          assessment.groupedChapterDetails[0].lessons &&
          assessment.groupedChapterDetails[0].lessons[0];

        // For Grade-1 course
        if (assessment.MasterCourse.alias_course_name === "Grade - 1") {
          return (
            <Box key={`grade-1-${index}`} sx={{ marginBottom: index < data.takeAssessment.length - 1 ? "2rem" : 0 }}>
              <Card className="dashboard-card">
                <CardContent className="dashboard-card-content">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        className="dashboard-card-content__title"
                        variant="h5"
                      >
                        {assessment.MasterCourse?.course_name || ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="padding-top-0">
                      <Box className="dashboard-card-content__info">
                        <Box
                          className="dashboard-card-content__text-icon-wrapper"
                          mr={3}
                        >
                          <Box
                            component="img"
                            src={blueStar}
                            className="dashboard-card-content__icon"
                            id="star"
                          />
                          <Typography
                            className="dashboard-card-content__text"
                            variant="body1"
                          >
                            {assessment.MasterCourse?.RefCourseStats[0]?.avg_review || ""}
                          </Typography>
                        </Box>
                        <Box
                          className="dashboard-card-content__text-icon-wrapper"
                          mr={3}
                        >
                          <Box
                            component="img"
                            src={barchart}
                            className="dashboard-card-content__icon"
                          />
                          <Typography
                            variant="body1"
                            className="dashboard-card-content__text"
                          >
                            {assessment.MasterCourse?.RefCourseDifficulty?.name || ""}
                          </Typography>
                        </Box>
                        <Box className="dashboard-card-content__text-icon-wrapper">
                          <Box
                            component="img"
                            src={clock}
                            className="dashboard-card-content__icon"
                            id="clock"
                          />
                          <Typography
                            variant="body1"
                            className="dashboard-card-content__text"
                          >
                            {formatDuration(assessment.MasterCourse?.duration) || 0}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        mt={4}
                        variant="body2"
                        mb={4}
                        className="dashboard-card-content__description"
                      >
                        "This is Me"helps first graders explore their unique traits, boosting self-awareness and confidence. Parents gain insights, and teachers foster individuality in a supportive, inclusive environment
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {hasLesson && (
                        <Card className="dashboard-card-content__download">
                          <CardContent className="dashboard-card-content__download-content">
                            <Box className="dashboard-card-content__download-image-border">
                              <Box component="img" src={book} />
                            </Box>

                            <Box sx={{ flexGrow: 1 }}>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                className="dashboard-card-content__download-content-main-text"
                              >
                                {assessment.groupedChapterDetails[0].lessons[0].lesson_name || ""}
                              </Typography>
                            </Box>

                            <IconButton
                              edge="end"
                              sx={{ p: 0 }}
                              className="dashboard-card-content__download-content--iconButton"
                            >
                            </IconButton>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>

                    <Grid item xs={12} mt={2} mb={2}>
                      <Link
                        to={`/app/course/${assessment.MasterCourse.slug}/learn/${assessment.groupedChapterDetails[0].lessons[0].lesson_slug}`} >
                        <Button
                          className="dashboard-card__button"
                          variant="contained"
                        >
                          Start Learning
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          );
        }
        
        // For Grade-2 course
        if (assessment.MasterCourse.alias_course_name === "Grade - 2") {
          return (
            <Box key={`grade-2-${index}`} sx={{ marginBottom: index < data.takeAssessment.length - 1 ? "2rem" : 0 }}>
              <Card className="dashboard-card">
                <CardContent className="dashboard-card-content">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        className="dashboard-card-content__title"
                        variant="h5"
                      >
                        {assessment.MasterCourse?.course_name || ""}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className="padding-top-0">
                      <Box className="dashboard-card-content__info">
                        <Box
                          className="dashboard-card-content__text-icon-wrapper"
                          mr={3}
                        >
                          <Box
                            component="img"
                            src={blueStar}
                            className="dashboard-card-content__icon"
                            id="star"
                          />
                          <Typography
                            className="dashboard-card-content__text"
                            variant="body1"
                          >
                            {assessment.MasterCourse?.RefCourseStats[0]?.avg_review || ""}
                          </Typography>
                        </Box>
                        <Box
                          className="dashboard-card-content__text-icon-wrapper"
                          mr={3}
                        >
                          <Box
                            component="img"
                            src={barchart}
                            className="dashboard-card-content__icon"
                          />
                          <Typography
                            variant="body1"
                            className="dashboard-card-content__text"
                          >
                            {assessment.MasterCourse?.RefCourseDifficulty?.name || ""}
                          </Typography>
                        </Box>
                        <Box className="dashboard-card-content__text-icon-wrapper">
                          <Box
                            component="img"
                            src={clock}
                            className="dashboard-card-content__icon"
                            id="clock"
                          />
                          <Typography
                            variant="body1"
                            className="dashboard-card-content__text"
                          >
                            {formatDuration(assessment.MasterCourse?.duration) || 0}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        mt={4}
                        variant="body2"
                        mb={4}
                        className="dashboard-card-content__description"
                      >
                        "Special Me" helps second graders explore their unique traits, boosting self-awareness and confidence. Parents gain insights, and teachers foster individuality in a supportive, inclusive environment.
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {hasLesson && (
                        <Card className="dashboard-card-content__download">
                          <CardContent className="dashboard-card-content__download-content">
                            <Box className="dashboard-card-content__download-image-border">
                              <Box component="img" src={book} />
                            </Box>

                            <Box sx={{ flexGrow: 1 }}>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                className="dashboard-card-content__download-content-main-text"
                              >
                                {assessment.groupedChapterDetails[0].lessons[0].lesson_name || ""}
                              </Typography>
                            </Box>

                            <IconButton
                              edge="end"
                              sx={{ p: 0 }}
                              className="dashboard-card-content__download-content--iconButton"
                            >
                            </IconButton>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>

                    <Grid item xs={12} mt={2} mb={2}>
                      <Link
                        to={`/app/course/${assessment.MasterCourse.slug}/learn/${assessment.groupedChapterDetails[0].lessons[0].lesson_slug}`} >
                        <Button
                          className="dashboard-card__button"
                          variant="contained"
                        >
                          Start Learning
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          );
        }

        // For assessment quiz
        return (
          <Box key={`quiz-${index}`} className="dashboard-quiz" sx={{ marginBottom: index < data.takeAssessment.length - 1 ? "2rem" : 0 }}>
            <Box component="img" src={quiz} alt="quiz" className="dashboard-quiz__image" />
            <Box className="dashboard-quiz__content">
              <Typography className="dashboard-quiz__content--title">
                {assessment.MasterCourse.course_name || "Assessment Quiz"}
              </Typography>
              <Typography className="dashboard-quiz__content--description">
                Unlock insights into your abilities and personal growth potential with this comprehensive quiz. This assessment will provide valuable feedback on key aspects of your personal and professional skills.
              </Typography>
            </Box>
            {hasLesson && (
              <Box className="dashboard-quiz__assessment">
                <Link
                  className="dashboard-quiz__assessment-link"
                  to={`/app/course/${assessment.MasterCourse.slug}/learn/${assessment.groupedChapterDetails[0].lessons[0].lesson_slug}`}
                >
                  <Typography className="dashboard-quiz__assessment-link--text">
                    Take Assessment Quiz
                  </Typography>
                  <Box
                    className="dashboard-quiz__assessment-link--button"
                    component="img"
                    src={chevronRight}
                    alt="chevron"
                  />
                </Link>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default DashboardQuizCard;