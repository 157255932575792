import React, { memo, useContext, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Check, Download, Share2 } from 'lucide-react';
import { FaCheck } from 'react-icons/fa6';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import VideoPlayerSkeleton from 'components/common/skeleton/videoPlayerSkeleton';

// Custom hooks for API requests
import useGetRequest from 'hooks/useGetRequest';
import usePostRequest from 'hooks/usePostRequest';
import { API_ENDPOINTS } from 'config/ApiConfig';
// Context imports
import LessonContext from 'context/lessonContext/LessonContext';
import UserContext from 'context/userContext/UserContext';
import CourseViewContext from 'context/courseViewContext/CourseViewContext';
import NofityUserContext from 'context/notifyUserContext/NotifyUserContext';
import { useLocation } from 'react-router-dom';


// Configure PDF worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

// FeedbackModal Component
const FeedbackModal = ({ onSubmit, onClose, autoReopen = true, isSubmitting }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleSubmit = async () => {
    if (!rating || !review.trim() || isSubmitting) return;
    await onSubmit({ rating, review_text: review });
  };

  const handleClose = () => {
    onClose();
    if (autoReopen) {
      setTimeout(() => {
        onClose(false);
      }, 10000000000000);
    }
  };

  // Rating emojis configuration
  const emojis = [
    { rating: 1, emoji: '🥵', label: 'Worst' },
    { rating: 2, emoji: '😔', label: 'Bad' },
    { rating: 3, emoji: '😑', label: 'Average' },
    { rating: 4, emoji: '🙂', label: 'Good' },
    { rating: 5, emoji: '🥰', label: 'Excellent' },
  ];

  return (
    <div style={{
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 60,
      padding: '20px',
      marginTop: '4rem'
    }}>
      {/* Modal content */}
      <div style={{
        backgroundColor: 'white',
        borderRadius: '16px',
        maxWidth: '28rem',
        width: '100%',
        margin: '0 16px',
        padding: '24px'
      }}>
        {/* Modal header */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
          borderBottom: '1px solid rgba(161, 161, 161, 0.15)'
        }}>
          <h2 style={{
            fontSize: '24px',
            fontWeight: 'normal',
            fontFamily: 'Gilroy Medium'
          }}>Feedback</h2>
          <button 
            onClick={handleClose}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px'
            }}
          >✕</button>
        </div>

        {/* Feedback content */}
        <div style={{
          paddingBottom: '24px',
          marginBottom: '24px',
          marginTop: '16px',
        }}>
          <h3 style={{
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Gilroy SemiBold',
            textAlign: 'center',
            marginBottom: '8px',
            marginTop: '16px'
          }}>
            How do you feel about the course?
          </h3>
          <p style={{
            color: '#666666',
            fontFamily: 'Gilroy Regular',
            textAlign: 'center',
            marginTop: '8px'
          }}>
            Your input is valuable in helping students like you better and tailor our content accordingly
          </p>
        </div>

        {/* Rating emojis */}
        <div style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          marginBottom: '24px',
          height: '96px',
          alignItems: 'center'
        }}>
          {emojis.map(({ rating: emojiRating, emoji, label }) => (
            <div 
              key={emojiRating}
              style={{ position: 'relative' }}
              onMouseEnter={() => setHoveredRating(emojiRating)}
              onMouseLeave={() => setHoveredRating(null)}
            >
              <button
                onClick={() => setRating(emojiRating)}
                style={{
                  fontSize: '30px',
                  // padding: '8px',
                  borderRadius: '50%',
                  border: '1px solid #e5e7eb',
                  cursor: 'pointer',
                  position: 'relative',
                  zIndex: hoveredRating === emojiRating ? 20 : 10,
                  backgroundColor: rating === emojiRating ? '#dcfce7' : 'transparent',
                  transform: `scale(${hoveredRating === emojiRating ? 1.25 : rating === emojiRating ? 1.1 : hoveredRating && hoveredRating !== emojiRating ? 0.9 : 1})`,
                  transition: 'all 300ms',
                  filter: hoveredRating && hoveredRating !== emojiRating ? 'grayscale(1) opacity(0.5)' : 'none'
                }}
              >
                {emoji}
                {hoveredRating === emojiRating && (
                  <>
                    <div style={{
                      position: 'absolute',
                      inset: '-8px',
                      backgroundColor: '#dcfce7',
                      borderRadius: '50%',
                      zIndex: -1,
                      opacity: 0.5,
                      animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite'
                    }} />
                    <div style={{
                      position: 'absolute',
                      top: '-32px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      whiteSpace: 'nowrap',
                      backgroundColor: '#1f2937',
                      color: 'white',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      fontSize: '14px',
                      fontFamily: 'Gilroy Regular'
                    }}>
                      {label}
                    </div>
                  </>
                )}
              </button>
            </div>
          ))}
        </div>

        {/* Review textarea */}
        <textarea
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Add your review..."
          style={{
            width: '80%',
            margin: '0 10%',
            padding: '12px',
            border: '1px solid rgb(76,80,83)',
            borderRadius: '16px',
            marginBottom: '16px',
            height: '128px',
            resize: 'none',
            boxSizing: 'border-box',
            fontFamily: 'Gilroy Regular'
          }}
        />

        {/* Submit button */}
        <button
          onClick={handleSubmit}
          disabled={!rating || !review.trim() || isSubmitting}
          style={{
            width: '80%',
            margin: '0 10%',
            padding: '8px',
            borderRadius: '24px',
            border: '1px solid #2B3D4F',
            transition: 'colors 300ms',
            color: '#FAFDFF',
            fontFamily: 'Gilroy Bold',
            backgroundColor: rating && review.trim() && !isSubmitting ? '#2B3D4F' : '#d1d5db',
            cursor: rating && review.trim() && !isSubmitting ? 'pointer' : 'not-allowed'
          }}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

// Main CourseCompletion Component
const CourseCompletion = () => {
  // Context hooks
  const { courseData } = useContext(CourseViewContext);
  const { sortedLessonData } = useContext(LessonContext);
  const { userData } = useContext(UserContext);
  const { notifyUser } = useContext(NofityUserContext);
  const { slug } = useParams();

  // State hooks
  const [currentView, setCurrentView] = useState('congratulations');
  const [showConfetti, setShowConfetti] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [feedbackDetails, setFeedbackDetails] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoadingPdf, setIsLoadingPdf] = useState(true);
  const { width, height } = useWindowSize();
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const location = useLocation(); // Add this import and hook

  // Custom API hooks
  const {
    data: createCertificateData,
    loading: loadingCreateCertificate,
    postData: postCreateCertificate,
  } = usePostRequest(API_ENDPOINTS.CREATE_COURSE_COMPLETITION_CERTIFICATE);

  const {
    data: certificateAndFeedbackData,
    loading: loadingCertificateAndFeedback,
    getData: getCertificateAndFeedback,
  } = useGetRequest(API_ENDPOINTS.GET_CERTIFICATE_AND_FEEDBACK);

  const {
    data: feedbackSubmissionData,
    loading: loadingFeedbackSubmission,
    postData: postFeedbackSubmission,
  } = usePostRequest(API_ENDPOINTS.STORE_COURSE_FEEDBACK);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!initialDataFetched && sortedLessonData?.length > 0) {
        const slug = sortedLessonData[0]?.course_slug || "grade---6";
        await getCertificateAndFeedback(slug);
        setInitialDataFetched(true);
      }
    };
    fetchInitialData();
  }, [sortedLessonData, initialDataFetched]);

  // // Effect to fetch initial certificate and feedback data
  // useEffect(() => {
  //   const fetchInitialData = async () => {
  //     if (!initialDataFetched && sortedLessonData?.length > 0) {
  //       const slug = sortedLessonData[0]?.course_slug || "grade---6";
  //       await getCertificateAndFeedback(slug);
  //       setInitialDataFetched(true);
  //     }
  //   };
  //   fetchInitialData();
  // }, [sortedLessonData, initialDataFetched]);

  // Effect to check course completion and trigger certificate creation
  useEffect(() => {
    const isAllCompleted = sortedLessonData?.length > 0 && 
      sortedLessonData.every(chapter => 
        chapter.lessons && chapter.lessons.every(lesson => lesson.isCompleted)
      );

    if (isAllCompleted ) {
      setShowConfetti(true);
      const timer = setTimeout(() => {
        setShowConfetti(false);
    createCertificate();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [sortedLessonData]);

  // Effect to update PDF URL when certificate details change
  useEffect(() => {
    if (certificateDetails) {
      setPdfUrl(`${API_ENDPOINTS.DOWNLOAD_CERTIFICATE}/${certificateDetails}.pdf`);
    }
  }, [certificateDetails]);

  // Certificate creation handler
  const createCertificate = async () => {
    try {
      await postCreateCertificate({ slug: "grade---6" });
      if (createCertificateData) {
        setCertificateDetails(createCertificateData.certificate_code);
        setCurrentView('certificate');
        setTimeout(() => {
          setShowFeedbackModal(true);
        }, 1000);
      }
    } catch (error) {
      console.error("Error creating certificate:", error);
      notifyUser("Error", "Failed to create certificate", "error");
    }
  };


  // Feedback submission handler
  const handleFeedbackSubmit = async (feedbackData) => {
    try {
      const courseId = sortedLessonData[0]?.course_id;
      await postFeedbackSubmission({
        course_id: courseId,
        ...feedbackData
      });

      if (feedbackSubmissionData) {
        setFeedbackDetails(feedbackSubmissionData.feedbackDetailscourse);
        setShowFeedbackModal(false);
        notifyUser("Success", "Thank you for your feedback!", "success");
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      notifyUser("Error", "Failed to submit feedback. Please try again.", "error");
    }
  };

  // Share certificate handler
  const handleShare = async () => {
    if (!certificateDetails) {
      notifyUser("Error", "Certificate code not found", "error");
      return;
    }

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'My Course Completion Certificate',
          url: pdfUrl,
        });
      } else {
        notifyUser(
          "Failed to share",
          "Share functionality is not supported in this browser. Please copy the link to share.",
          "error"
        );
      }
    } catch (error) {
      console.error('Error sharing:', error);
      notifyUser("Error", "Failed to share certificate", "error");
    }
  };

  // Download certificate handler
  const handleDownload = async () => {
    if (!certificateDetails) {
      notifyUser("Error", "Certificate code not found", "error");
      return;
    }
    
    try {
      const response = await fetch(`${API_ENDPOINTS.DOWNLOAD_CERTIFICATE}/${certificateDetails}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `certificate-${certificateDetails}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      notifyUser("Success", "Certificate downloaded successfully!", "success");
    } catch (error) {
      console.error('Error downloading certificate:', error);
      notifyUser("Error", "Failed to download certificate. Please try again.", "error");
    }
  };

  // Utility function to capitalize first letter
  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };

  // Render feedback section
  const renderFeedback = () => {
    if (!feedbackDetails) return null;

    return (
      <div style={{
        marginTop: '24px',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#f3f4f6',
        maxWidth: '56rem',
        margin: '24px auto'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '12px'
        }}>
          {feedbackDetails.User?.profile_pic && (
            <img 
              src={feedbackDetails.User.profile_pic} 
              alt="Profile" 
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover'
              }}
            />
          )}
          <div>
            <h4 style={{
              fontFamily: 'Gilroy SemiBold',
              fontSize: '16px',
              marginBottom: '4px'
            }}>
              {feedbackDetails.User?.first_name} {feedbackDetails.User?.last_name}
            </h4>
            <p style={{
              fontFamily: 'Gilroy Regular',
              fontSize: '14px',
              color: '#6b7280'
            }}>
              {new Date(feedbackDetails.updated_on).toLocaleDateString()}
            </p>
          </div>
        </div>
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '8px'
        }}>
          <span style={{
            color: '#2B3D4F',
            fontFamily: 'Gilroy Bold'
          }}>
            Rating: {feedbackDetails.rating}/5
          </span>
        </div>
        
        <p style={{
          fontFamily: 'Gilroy Regular',
          fontSize: '14px',
          lineHeight: '1.5'
        }}>
          {feedbackDetails.review_text}
        </p>
      </div>
    );
  };

  // Render congratulations view
  const renderCongratulations = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '160px',
      marginBottom: '32px'
    }}>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={true}
          numberOfPieces={200}
        />
      )}
      
      <div style={{
        width: '160px',
        height: '160px',
        backgroundColor: '#237455',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '32px'
      }}>
        <FaCheck style={{
          width: '96px',
          height: '96px',
          color: 'white'
        }} />
      </div>

      <h3 style={{
        fontSize: '36px',
        fontWeight: 400,
        color: '#2B3D4F',
        marginBottom: '32px',
        fontFamily: 'Gilroy SemiBold',
        textAlign: 'center'
      }}>
        Well Done {capitalizeFirstLetter(userInfo?.first_name || '')}!
      </h3>

      <p style={{
        fontSize: '18px',
        color: 'black',
        fontFamily: 'Gilroy Medium',
        textAlign: 'center',
        width: '40%',
        margin: '0 auto'
      }}>
        Congratulations on completing your Life Skills Course {capitalizeFirstLetter(courseData?.course_name || '')}
        {courseGrade ? ` - Grade ${courseGrade}` : ""}. Keep Learning
      </p>
    </div>
  );

  // Render certificate view
  const renderCertificate = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 50,
      backgroundColor: 'white',
      padding: '32px',
      overflowY: 'auto'
    }}>
      <div style={{
        maxWidth: '56rem',
        width: '100%',
        margin: '0 auto',
        padding: '24px'
      }}>
        <div style={{
          borderRadius: '8px',
          marginBottom: '24px',
          overflow: 'hidden'
        }}>
          <Document
            file={pdfUrl}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              setIsLoadingPdf(false);
            }}
            loading={<VideoPlayerSkeleton />}
          >
            {!isLoadingPdf && numPages > 0 && (
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={400}
              />
            )}
          </Document>
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px'
        }}>
          <button
            onClick={handleDownload}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              padding: '12px 32px',
              borderRadius: '9999px',
              border: '1px solid #2B3D4F',
              backgroundColor: 'white',
              color: '#3B5269',
              fontFamily: 'Gilroy Bold',
              cursor: 'pointer'
            }}
          >
          
            Download
          </button>
          
          <button
            onClick={handleShare}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              padding: '12px 40px',
              borderRadius: '9999px',
              border: '1px solid #2B3D4F',
              backgroundColor: 'white',
              color: '#3B5269',
              fontFamily: 'Gilroy Bold',
              cursor: 'pointer'
            }}
          >

            Share
          </button>
        </div>

        {renderFeedback()}
      </div>
    </div>
  );

  // Guard clauses for data validation
  if (!sortedLessonData?.length || !userData?.length) {
    return null;
  }

  const userInfo = userData.find(item => typeof item === 'object' && 'first_name' in item);
  const courseName = capitalizeFirstLetter(courseData?.course_name || '');
  const courseGrade = sortedLessonData[0]?.grade;

  if (!userInfo || !courseName) {
    return null;
  }

  // Main component render
  return (
    <div>
      {currentView === 'congratulations' && renderCongratulations()}
      {currentView === 'certificate' && renderCertificate()}
      {showFeedbackModal && (
        <FeedbackModal
          onSubmit={handleFeedbackSubmit}
          onClose={() => setShowFeedbackModal(false)}
        />
      )}
    </div>
  );
};

export default memo(CourseCompletion);