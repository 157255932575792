import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Paper, TextField, Button, Stack } from '@mui/material';
import Saved from "assets/forum/bookmark.svg";
import Flag from "assets/forum/flag.svg";
import Thumbsup from "assets/forum/thumbsup.svg";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Grid, List, ListItem, ListItemText } from '@mui/material';
import Plus from "assets/forum/plus.svg";
import Question from "assets/forum/question.svg";
import axios from 'axios';
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import { Avatar } from '@mui/material';
import { decryptData } from "utilityFunction/encryption&Decryption";
import AppContext from 'context/appContext/AppContext';
import { use } from 'react';


const ThreadDetailPage = () => {
  const { currentUrl, setCurrentUrl } = useContext(AppContext);
  const [newResponse, setNewResponse] = useState('');
  const navigate = useNavigate();
  const { slug, thread_id } = useParams();

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [activeView, setActiveView] = useState('all');
  const [userThreads, setUserThreads] = useState([]);
  const [bookmarkedThreads, setBookmarkedThreads] = useState([]);
 // Update initial states for likes
 const [isLiked, setIsLiked] = useState(false);
 const [likeCount, setLikeCount] = useState(0);

 const [isreplyLiked, setIsReplyLiked] = useState(false);
 const [replyLikeCount, setReplyLikeCount] = useState(0);
 const [retryAttempt, setRetryAttempt] = useState(0);
 const maxRetries = 100; // Large number of retries
 const retryDelay = 2000; // 2 seconds between retries


    const location = useLocation(); // Get the current location

    // Capture the entire URL
    const currentUrl1 = `${window.location.origin}${location.pathname}${location.search}`;

    // Check if "learn" is present in the URL
    const showReportContent = location.pathname.includes('forum');

    useEffect(() => {
        // Update the context only if "learn" is present
        if (showReportContent) {
            setCurrentUrl(currentUrl1);
        }
    }, [currentUrl1, showReportContent, setCurrentUrl]);

    // Fetch thread data with error handling
    const {
        data: threadData,
        loading: threadLoading,
        error: threadError,
        getData: fetchThread
    } = useGetRequest(`${API_ENDPOINTS.GET_SINGLE_THREAD}/${thread_id}`);
    console.log(threadData);
    console.log("Thread data recieved");

    // State for managing replies
    const [replies, setReplies] = useState([]);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        let timeoutId;
    
        const attemptFetch = async () => {
          try {
            await fetchThread();
            if (threadData) {
              setRetryAttempt(0); // Reset retry count on success
              // Set replies and user data when thread data is available
              setReplies(threadData.replies || []);
              setUserData(threadData.User);
            } else {
              // If no data, continue retrying
              timeoutId = setTimeout(() => {
                setRetryAttempt(prev => prev + 1);
              }, retryDelay);
            }
          } catch (error) {
            // On error, continue retrying
            timeoutId = setTimeout(() => {
              setRetryAttempt(prev => prev + 1);
            }, retryDelay);
          }
        };
    
        if (thread_id) {
          attemptFetch();
        }
    
        // Cleanup timeout on unmount
        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }, [retryAttempt, thread_id]); // Retry whenever retryAttempt changes
    


    useEffect(() => {
        if (thread_id) {
            fetchThread();
        }
    }, [thread_id]);

    useEffect(() => {
        if (threadData) {
            // Since replies are available directly in the response
            setReplies(threadData.replies || []);
            // Update user data from threadData directly
            setUserData(threadData.User);
        }
    }, [threadData, replies]);

  // Fetch threads based on active view (similar to ForumPage)
  const fetchAppropriateThreads = async () => {
    try {
        if (activeView === 'user') {
            const response = await axios.get(API_ENDPOINTS.VIEW_MY_THREADS);
            setUserThreads(response.data.existingThread || []);
        } else if (activeView === 'saved') {
            const response = await axios.get(API_ENDPOINTS.VIEW_ALL_BOOKMARKS);
            setBookmarkedThreads(response.data.existingBookmark || []);
        }
    } catch (error) {
        console.error('Error fetching threads:', error);
    }
};


useEffect(() => {
  fetchAppropriateThreads();
}, [activeView]);

 // Add effect to initialize like count
 useEffect(() => {
    if (threadData) {
        setLikeCount(threadData.threadLikesCount || 0);
    }
}, [threadData]);

useEffect(() => {
    if (threadData) {
        setReplyLikeCount(replies.likeCount || 0);
    }
}, [threadData, replies]);


const handleSidebarItemClick = (view) => {
  setActiveView(view);
  if (view === 'all') {
      navigate(`/app/forum/${slug}`);
  }
};

const handleBack = () => {
  navigate(-1);
};


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = Math.abs(now - date); // Difference in milliseconds

        // Calculate differences in units
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        // Adjust "Just now" buffer to cover up to 5 minutes
        if (diffMinutes < 5) {
            return "Just now";
        } else if (diffMinutes < 60) {
            return `${diffMinutes} ${diffMinutes === 1 ? "minute" : "minutes"} ago`;
        } else if (diffHours < 24) {
            return `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`;
        } else {
            return `${diffDays} ${diffDays === 1 ? "day" : "days"} ago`;
        }
    };


    const getInitials = (firstName, lastName) => {
      try {
          const decryptedFirst = firstName ? decryptData(firstName) : '';
          const decryptedLast = lastName ? decryptData(lastName) : '';
          if (!decryptedFirst && !decryptedLast) return "U";
          const firstInitial = decryptedFirst ? decryptedFirst.charAt(0).toUpperCase() : '';
          const lastInitial = decryptedLast ? decryptedLast.charAt(0).toUpperCase() : '';
          return `${firstInitial}${lastInitial}` || "U";
      } catch (error) {
          console.error("Error decrypting name data:", error);
          return "U";
      }
  };


   const handleSubmitResponse = async () => {
        if (!newResponse.trim()) return;

        try {
            const response = await axios.post(API_ENDPOINTS.REPLY_TO_THREAD, {
                thread_id: thread_id,
                content: newResponse,
            });

            if (response.data.reply) {
                const newReply = {
                    ...response.data.reply,
                    added_on: new Date().toISOString(),
                    User: userData // Add the current user data to the reply
                };
                setReplies(prevReplies => [...prevReplies, newReply]);
                setNewResponse('');
            }
        } catch (error) {
            console.error('Error submitting response:', error);
        }
    };

    // if (threadLoading) {
    //     return (
    //         <Box sx={{ p: 5, textAlign: 'center' }}>
    //             <Typography>Loading...</Typography>
    //         </Box>
    //     );
    // }

    if (threadError) {
        return (
            <Box sx={{ p: 5, textAlign: 'center' }}>
                <Typography color="error">
                 
                </Typography>
            </Box>
        );
    }

   // Update this check to look for threadData directly
   if (!threadData) {
    return (
        <Box sx={{ p: 5, textAlign: 'center' }}>
            <Typography>
            
            </Typography>
        </Box>
    );
}

    const handleToggleBookmark = async () => {
      try {
          await axios.post(API_ENDPOINTS.TOGGLE_BOOKMARK, {
              thread_id: thread_id
          });
          setIsBookmarked(!isBookmarked);
          fetchAppropriateThreads();
      } catch (error) {
          console.error('Error toggling bookmark:', error);
      }
  };


console.log(threadData.User);


 const handleToggleLike = async () => {
    try {
        await axios.post(API_ENDPOINTS.TOGGLE_LIKE, {
            thread_id: thread_id , 
            thread_reply_id: replies.thread_reply_id
        });
        setIsLiked(!isLiked);
        setIsReplyLiked(!isreplyLiked);
        // Update like count based on action
        setLikeCount(prev => isLiked ? prev - 1 : prev + 1);
        setReplyLikeCount(prev => isreplyLiked ? prev - 1 : prev + 1);

    } catch (error) {
        console.error('Error toggling like:', error);
    }
};

const formatUserName = (user) => {
    if (!user) return "User";
    try {
        const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

        const firstName = user.first_name ? capitalize(decryptData(user.first_name)) : '';
        const lastName = user.last_name ? capitalize(decryptData(user.last_name)) : '';
        
        return `${firstName} ${lastName}`.trim() || "User";
    } catch (error) {
        console.error("Error decrypting name:", error);
        return "User";
    }
};



    return (
        <Grid container sx={{ height: "100%", width: "100%", display: "flex" }}>
            {/* Sidebar */}
            <Grid
                item
                xs={3}
                sx={{
                    bgcolor: 'rgba(245, 250, 255, 1)',
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    position: "relative",
                    overflowY: "auto",
                    alignItems: "center",
                    borderRight: "1px solid rgba(0, 0, 0, 0.1)"
                }}
            >
                <Box sx={{
                    width: "100%",
                    p: 3,
                    overflowY: "auto",
                    flex: 1,
                }}>
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            ml: 2,
                            mt: 1,
                            fontFamily: "Gilroy SemiBold",
                            fontSize: "25px",
                            fontWeight: 400,
                            lineHeight: "50px",
                            color: "rgba(43, 61, 79, 1)",
                        }}
                    >
                        MTS Forum
                    </Typography>

                    <List sx={{ width: "100%", p: 0 }}>
                        <ListItem 
                        button 
                        onClick={() => handleSidebarItemClick('all')}
                        sx={{ mb: 0, px: 2, py: 1 }}>
                            <Box
                                component="img"
                                src={Plus}
                                alt="Home"
                                sx={{ width: "25px", height: "25px", mr: 2 }}
                            />
                            <ListItemText
                                primary={
                                    <Typography sx={{
                                        fontFamily: "Gilroy Medium",
                                        fontSize: "18px",
                                        color: activeView === 'all' ? 'rgba(57, 204, 204, 1)' : 'rgba(43, 61, 79, 1)'
                                    }}>
                                        Home
                                    </Typography>
                                }
                            />
                        </ListItem>

                        <ListItem 
                        button 
                        onClick={() => handleSidebarItemClick('user')}
                        sx={{ mb: 0, px: 2, py: 1 }}>
                            <Box
                                component="img"
                                src={Question}
                                alt="Threads"
                                sx={{ width: "25px", height: "25px", mr: 2 }}
                            />
                            <ListItemText
                                primary={
                                    <Typography sx={{
                                        fontFamily: "Gilroy Medium",
                                        fontSize: "18px",
                                       color: activeView === 'user' ? 'rgba(57, 204, 204, 1)' : 'rgba(43, 61, 79, 1)'
                                    }}>
                                        Your Threads
                                    </Typography>
                                }
                            />
                        </ListItem>

                        <ListItem 
                        button
                        onClick={() => handleSidebarItemClick('saved')}
                        sx={{ mb: 0, px: 2, py: 1 }}>
                            <Box
                                component="img"
                                src={Saved}
                                alt="Saved"
                                sx={{ width: "25px", height: "25px", mr: 2 }}
                            />
                            <ListItemText
                                primary={
                                    <Typography sx={{
                                        fontFamily: "Gilroy Medium",
                                        fontSize: "18px",
                                        color: activeView === 'saved' ? 'rgba(57, 204, 204, 1)' : 'rgba(43, 61, 79, 1)'
                                    }}>
                                        Saved
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>

                <Box


                    sx={{ p: 7, whiteSpace: 'nowrap' }}>




                    <ListItem
                        onClick={() => navigate(`/app/course/${slug}/learn`)}
                        button
                    >
                        <Typography
                            sx={{
                                fontFamily: "Gilroy Regular",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "rgba(43, 61, 79, 1)",
                                ml: -9
                            }}
                        >
                            {"<"} Back to Course Dashboard
                        </Typography>
                    </ListItem>
                </Box>
            </Grid>

            {/* Main Content */}
            <Grid item xs={9} sx={{ p: 5, bgcolor: "rgba(245, 250, 255, 1)", minHeight: "100vh", overflowY: "auto" }}>
                <Typography
                    onClick={handleBack}
                    sx={{
                        mb: 4,
                        cursor: 'pointer',
                        fontFamily: 'Gilroy Regular',
                        fontSize: '16px',
                        color: 'rgba(43, 61, 79, 1)',
                    }}
                >
                    {"< Back to all threads"}
                </Typography>

                {/* Thread Content */}
                <Paper elevation={0} sx={{ p: 4, mb: 3, borderRadius: '8px' }}>
                    <Typography
                        sx={{
                            fontFamily: 'Gilroy SemiBold',
                            fontSize: '24px',
                            color: 'rgba(43, 61, 79, 1)',
                            mb: 3
                        }}
                    >
                        {threadData.threadData.title}
                    
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Gilroy Regular',
                            fontSize: '16px',
                            color: 'rgba(90, 90, 90, 1)',
                            mb: 4
                        }}
                    >
                        {threadData.threadData.content}
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>


                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Gilroy SemiBold',
                                    fontSize: '16px',
                                    color: 'rgba(59, 82, 105, 1)',
                                }}
                            >
                          {threadData.threadData.User 
  ? `${decryptData(threadData.threadData.User.first_name).charAt(0).toUpperCase()}${decryptData(threadData.threadData.User.first_name).slice(1)}` 
  : `User ${threadData.threadData.created_by}`}

                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Gilroy Regular',
                                    fontSize: '14px',
                                    color: 'rgba(100, 110, 131, 1)',
                                }}
                            >
                                 {formatDate(threadData.threadData.added_on)}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                                onClick={handleToggleBookmark}

                            >
                                <Box
                                    component="img"
                                    src={Saved}
                                    alt="Save"
                                    sx={{ width: '20px', height: '20px', marginLeft: '8px', filter: isBookmarked ? 'brightness(0.7)' : 'none' }} />
                                <Typography sx={{ color: 'rgba(100, 110, 131, 1)', fontSize: '14px', fontFamily: 'Gilroy Regular', ml: 1 }}>
                                    {isBookmarked ? 'Saved' : 'Save'}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={handleToggleLike}
                            >
                                <Box
                                    component="img" src={Thumbsup} alt="Like" sx={{
                                        width: '20px',
                                        height: '20px',
                                        filter: isLiked ? 'brightness(0.7)' : 'none'

                                    }} />
                                <Typography sx={{
                                    fontFamily: 'Gilroy Regular',
                                    fontSize: '16px',
                                    color: 'rgba(100, 110, 131, 1)',
                                    ml: 1
                                }}>
                                     {likeCount}
                                </Typography>
                            </Box>

                            <Box
                                onClick={() => navigate(`/app/report-content`)}
                                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <Box

                                    component="img" src={Flag} alt="Report" sx={{ width: '20px', height: '20px' }} />
                                <Typography sx={{
                                    color: 'rgba(100, 110, 131, 1)',
                                    fontSize: '14px',
                                    fontFamily: 'Gilroy Regular',
                                    ml: 1
                                }}>
                                    Report
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Paper>

                {/* Add Response Section */}
                <Paper elevation={0} sx={{ p: 4, mb: 4, borderRadius: '8px' }}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Write your response..."
                        value={newResponse}
                        borderRadius="15px"
                        borderColor="rgba(43, 61, 79, 0.26)"
                        onChange={(e) => setNewResponse(e.target.value)}
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                fontFamily: 'Gilroy Regular',
                            }                         
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            borderRadius: '15px',
                            borderColor: 'rgba(43, 61, 79, 0.26)',
                        }}
                    >
                        <Box
                            onClick={handleSubmitResponse}
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'white',
                                color: 'rgba(59, 82, 105, 1)',
                                borderRadius: '30px',
                                border: '1px solid rgba(43, 61, 79, 0.26)',
                                px: 8,
                                py: 0.5,
                                marginTop: 2,
                                cursor: 'pointer',
                               
                            }}
                        >
                            <Typography sx={{ fontFamily: 'Gilroy Medium', fontSize: '16px', color: 'rgba(59, 82, 105, 1)' }}>
                                Submit
                            </Typography>
                        </Box>
                    </Box>
                </Paper>

                {/* Replies */}
                <Stack spacing={2}>
                    {replies.map(reply => (  
                        <Paper key={reply.thread_reply_id} elevation={0} sx={{ p: 4, borderRadius: '8px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Gilroy Regular',
                                    fontSize: '16px',
                                    color: 'rgba(90, 90, 90, 1)',
                                    mb: 2,
                                    mr: 2,
                                    // marginLeft: '90px'

                                }}
                            >
                                {reply.content}
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',

                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Gilroy SemiBold',
                                            fontSize: '16px',
                                            color: 'rgba(59, 82, 105, 1)',
                                        }}
                                    >
                                  {formatUserName(reply.User)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Gilroy Regular',
                                            fontSize: '14px',
                                            color: 'rgba(100, 110, 131, 1)'
                                        }}
                                    >
                                        {formatDate(reply.added_on)}
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box 
                                     onClick={handleToggleLike}
                                    component="img" src={Thumbsup} alt="Like" sx={{ width: '20px', height: '20px', cursor: 'pointer',  }} />
                                    <Typography sx={{
                                        fontFamily: 'Gilroy Regular',
                                        fontSize: '16px',
                                        color: 'rgba(100, 110, 131, 1)',
                                    }}>
                                        {replyLikeCount}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    ))}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default ThreadDetailPage;