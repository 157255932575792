import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import React, { useEffect, useState, useContext } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";
import usePostRequest from "hooks/usePostRequest";
import QuizFeedback from "./QuizFeedback";
import QuizTimer from "./TimerCard";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizResultCard from "./QuizResultCard";

const EmojiQuiz = ({ content }) => {
  const { message, notifyUser, clearUserNotification } = useContext(NofityUserContext);
  const { lessonInfo, sortedLessonData, lessonIdentifiers } = useContext(LessonContext);
  const { data: saveQuixResponseData, postData: saveOptionQuizResponse } = usePostRequest(API_ENDPOINTS.SAVE_OPTION_QUIZ_PROGRESS);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const [display, setLoading] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const {updateLessonCompletionData} = useContext(LessonContext);

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  useEffect(() => {
    setTimerKey(timerKey + 1); // Reset timer when quiz starts
  }, [quizStarted]);

  useEffect(() => {

    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(item => item.content_id === content);

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    } else {
      // console.log("lessonInfo is not available or does not contain content_id.");
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.emojiOptions[0].quiz_op_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);



useEffect(() => {
    if (quizStatResponse && contentDetails1 && contentDetails) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);

        if (contentDetails.emojiOptions.length > 0 && contentDetails.emojiOptions) {
          setQuizData(contentDetails.emojiOptions[currentQuestionIndex]);
        }
        setLoading(true);
      }
    }
  }, [quizStatResponse, contentDetails1, contentDetails, currentQuestionIndex]);

  useEffect(() => {
    if (quizStatApiResponse) {
      // console.log("quizStatDetails---> ", quizStatApiResponse);
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_op_id === detail.response_quiz_op_id
        );
        
        // Ensure MasterQuizLongAnswer and MasterQuestion are defined
        const question = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.question
          : null;
        const questionScore = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.score
          : null;
  
        return {
          id: detail.response_quiz_op_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: questionScore,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });
      setModalData(combinedData);
      // console.log("combinedData op emoji---> ", combinedData);
    }
  }, [quizStatApiResponse]);


  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };


  const handleOptionClick = (questionId, option) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option,
    }));
    setErrorMessage("");  // Clear any error message when an option is selected
  };

  const handleNavigation = (direction) => {
    if (contentDetails && contentDetails.emojiOptions) {
      const totalQuestions = contentDetails.emojiOptions.length;
      const currentQuestion = quizData.question_id;

      // Check if an answer is selected only when moving to the next question
      if (direction === "next" && !selectedAnswers[currentQuestion]) {
        notifyUser("Select Emoji", "Select an option", "warning");
        setErrorMessage("");
        return; // Prevent navigation to the next question if no option is selected
      }

      // Calculate the new question index based on direction
      let newQuestionIndex = direction === "next" ? currentQuestionIndex + 1 : currentQuestionIndex - 1;

      // Ensure the new question index is within the valid range
      newQuestionIndex = Math.max(0, Math.min(newQuestionIndex, totalQuestions - 1));

      // Update the current question index and move to the next/previous question
      setCurrentQuestionIndex(newQuestionIndex);
    }
  };


  const handleSave = async () => {
    setIsSaving(true);
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    // Loop through all the selected answers and submit each one separately
    for (const [questionId, selectedAnswer] of Object.entries(selectedAnswers)) {
      // Find the quiz item by question_id
      const quizItem = contentDetails.emojiOptions.find(item => item.question_id === parseInt(questionId));

      // Find the selected option based on the answer (true/false)
      const selectedOption = quizItem.options.find(option => option.option_name === selectedAnswer);

      // Construct the payload with the correct quiz_op_id
      const payLoad = {
        course_id: lessonIdentifiers.course_id,
        quiz_op_id: quizItem.quiz_op_id,
        quiz_id: quizItem.question_id,
        option_id: selectedOption.option_id,
        answer_text: selectedAnswer,
        completion_time:timeTakenInMinutes,
      };

      try {
        // Submit the quiz response using the saveOptionQuizResponse function
        await saveOptionQuizResponse(payLoad);
        console.log(`Submitted response for question ${quizItem.question_id}`);
      } catch (error) {
        console.error(`Failed to submit response for question ${quizItem.question_id}`, error);
      }
    }
    setQuizSubmitted(true);
    updateLessonCompletionData(content);
    // Notify the user of successful submission
    //notifyUser("Quiz Submitted", "Your responses have been saved successfully!", "success");
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.emojiOptions[0].quiz_op_id, lessonIdentifiers?.course_id)
      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  const handleTimeUp = async () => {
    setSelectedAnswers({});
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
    data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizOption.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return  <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizOption
            .quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
  }

  // Add this function to check if current question has been answered
  const isCurrentQuestionAnswered = () => {
    if (!quizData) return false;
    return Boolean(selectedAnswers[quizData.question_id]);
  };

  // Add this function to check if save button should be enabled
  const isSaveEnabled = () => {
    if (isSaving) return false;
    if (!quizData) return false;
    return isCurrentQuestionAnswered();
  };


  return display ? (
    <>
      {!quizStarted && (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && (
        <Box
          className="SAQ_wrapper"
          sx={{ borderTop: "170px solid #e4efff !important" }}
        >
          <Box className="SAQ_wrapper__Question-box">
            <Box className="SAQ_wrapper__Question-box--number">
              <Typography className="SAQ_wrapper__Question-box--number-text">
                Question {currentQuestionIndex + 1} / {contentDetails.emojiOptions.length}
              </Typography>
            </Box>
            <Typography className="SAQ_wrapper__Question-box--question">
              {quizData.question}
            </Typography>
          </Box>
          <Box className="SAQ_wrapper__answer-wrapper">
            <Box className="SAQ_wrapper__options-wrapper">
              {estimatedCompletionTime && (
                <QuizTimer key={timerKey} estimatedCompletionTime={estimatedCompletionTime} handleComplete={handleTimeUp}
/>
              )}
              <Grid container spacing={4} justifyContent="center" alignItems="center">
                {quizData.options.map((option) => (
                  <Grid
                    key={option.option_id}
                    item
                    xs={12 / 5} // 5 emojis per row
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      onClick={() => handleOptionClick(quizData.question_id, option.option_name)}
                      className={`SAQ_wrapper__emoji-button ${selectedAnswers[quizData.question_id] === option.option_name ? "SAQ_wrapper__emoji-button__selected" : ""
                        }`} // Add "selected" class if the option is clicked
                    >
                      <Box
                        className="SAQ_wrapper__emoji-button__size"
                        dangerouslySetInnerHTML={{ __html: option.option_name }} // Render emoji here
                      />
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box className="SAQ_wrapper-navigate" id="no-bg">
            <Button
              className="SAQ_wrapper-navigate__button"
              variant="outlined"
              id="white-bg"
              onClick={() => handleNavigation("previous")}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </Button>
            {currentQuestionIndex === contentDetails.emojiOptions.length - 1 ? (
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                id="white-bg"
                onClick={handleSave}
                disabled={!isSaveEnabled()}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            ) : (
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                id="white-bg"
                onClick={() => handleNavigation("next")}
              >
                Next
              </Button>
              )}
            </Box>
          </Box>
          <LinearProgress
            className="SAQ_wrapper__progress"
            variant="determinate"
            value={((currentQuestionIndex + 1) / contentDetails.emojiOptions.length) * 100}
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '8px !important',
              borderBottomLeftRadius: '30px',
              borderBottomRightRadius: '30px',
              backgroundColor: '#2b3d4f !important',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#39cccc !important',
              },
              zIndex: 20
            }}
          />

        </Box>
      )}
    </>

  ) : (
    <Typography></Typography>
  );
};

export default EmojiQuiz;
