import React, { useState } from 'react';
import { Box, IconButton, Popover, Typography, CircularProgress } from "@mui/material";
import { FaThumbtack, FaBookOpen } from "react-icons/fa";
import axios from "axios";

const ContextMenu = ({ contextMenu, onClose, onPin, menuType = "text" }) => {
  const [definition, setDefinition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dictionaryAnchor, setDictionaryAnchor] = useState(null);

  const selectWordAtPosition = (x, y) => {
    const range = document.caretRangeFromPoint(x, y);
    if (!range) return null;

    // Expand range to word boundaries
    range.expand('word');

    // Create selection
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    return range.toString().trim();
  };

  const fetchDefinition = async (word) => {
    if (!word) return null;
    
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
      );

      if (response.data && response.data.length > 0) {
        const firstMeaning = response.data[0].meanings[0];
        return {
          word: response.data[0].word,
          phonetic: response.data[0].phonetic || "",
          partOfSpeech: firstMeaning.partOfSpeech,
          definition: firstMeaning.definitions[0].definition,
        };
      }
    } catch (err) {
      setError("No definition found");
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const handleDictionaryClick = async () => {
    // First select the word
    const selectedWord = selectWordAtPosition(contextMenu.mouseX, contextMenu.mouseY);
    
    if (selectedWord) {
      // Set the anchor position for the dictionary popover
      setDictionaryAnchor({
        top: contextMenu.mouseY + 20,
        left: contextMenu.mouseX,
      });
      
      // Close the context menu immediately
      onClose();

      // Fetch and set the definition
      const definitionResult = await fetchDefinition(selectedWord);
      if (definitionResult) {
        setDefinition(definitionResult);
      }
    }
  };

  const handleDictionaryClose = () => {
    setDictionaryAnchor(null);
    setDefinition(null);
    setError(null);
    // Clear the selection when closing the dictionary
    window.getSelection().removeAllRanges();
  };

  if (!contextMenu) return null;

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: contextMenu.mouseY,
          left: contextMenu.mouseX,
          backgroundColor: "#fff",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          zIndex: 1000,
          padding: "8px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {(menuType === "pdf" || menuType === "all") && (
          <IconButton onClick={onPin} size="small">
            <FaThumbtack />
          </IconButton>
        )}
        {/* {(menuType === "text" || menuType === "all") && (
          <IconButton 
            onClick={handleDictionaryClick} 
            size="small"
          >
            <FaBookOpen />
          </IconButton>
        )} */}
      </Box>

      <Popover
        open={Boolean(dictionaryAnchor) && Boolean(definition)}
        anchorReference="anchorPosition"
        anchorPosition={dictionaryAnchor}
        onClose={handleDictionaryClose}
        PaperProps={{
          style: { padding: "12px", maxWidth: "300px" },
        }}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : definition && (
            <>
              <Typography variant="h6" fontWeight="bold">
                {definition.word}
              </Typography>
              {definition.phonetic && (
                <Typography variant="subtitle2" color="textSecondary">
                  {definition.phonetic}
                </Typography>
              )}
              <Typography variant="body1" color="textSecondary" fontStyle="italic">
                {definition.partOfSpeech}
              </Typography>
              <Typography variant="body2">{definition.definition}</Typography>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default ContextMenu;