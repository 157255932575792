import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import LessonContext from 'context/lessonContext/LessonContext';
import he from 'he';
import { API_ENDPOINTS } from 'config/ApiConfig';
import usePostRequest from 'hooks/usePostRequest';
//import ContextMenu from './ContextMenu';
import NofityUserContext from 'context/notifyUserContext/NotifyUserContext';

const ChapterContent = ({ content }) => {
    const { notifyUser } = useContext(NofityUserContext);

    const { lessonInfo, updateLessonCompletionData, lessonIdentifiers } = useContext(LessonContext);
    const [chapterContent, setChapterContent] = useState({
        heading: "",
        description: "",
    });
    const [contentDetails, setContentDetails] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);

    const { data: pinComponent, postData: pinContent } =
        usePostRequest(API_ENDPOINTS.POST_PIN_LESSON_QUIZ);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu({
        mouseX: event.clientX,
        mouseY: event.clientY,
    });
};
    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handlePinComponent = async () => {

        // notifyUser("Component pinned successfully", "success");
      
        notifyUser(
            "Component pinned successfully",
             "",
            "success"
          );

        console.log("Component pinned!");
       // await pinContent({ course_id: lessonIdentifiers?.course_id, content_id: content });
        handleCloseContextMenu();
    };

    const handleOpenDictionary = () => {
        // Add your dictionary functionality here
        handleCloseContextMenu();
    };

    useEffect(() => {
        if (lessonInfo) {
            const contentArray = lessonInfo || [];
            const foundContent = contentArray.find(item => item.content_id === content);

            if (foundContent) {
                setContentDetails(foundContent.contentDetails);
            } else {
                // console.log("Content not found for the provided content_id:", content);
            }
        } else {
            // console.log("lessonInfo is not available or does not contain content_id.");
        }
    }, [lessonInfo, content]);

    useEffect(() => {
        if (contentDetails) {
            // Clean the HTML content by replacing &nbsp; with a space
            let cleanHtmlContent = contentDetails.html_content.replace(/&nbsp;/g, ' ');

            // Decode any HTML entities (including emojis) in the content
            cleanHtmlContent = he.decode(cleanHtmlContent);

            // Set the chapter content from the content prop
            setChapterContent({
                heading: '',
                description: cleanHtmlContent,
            });
        }
    }, [contentDetails]);

    useEffect(() => {
        if (content) {
            updateLessonCompletionData(content);
        }
    }, []);

    return (
        <Box className="chapter-content" onContextMenu={handleContextMenu}>
            {/* <Typography className="chapter-content__heading">
                {chapterContent.heading}
            </Typography> */}
            <div
                className="chapter-content__description"
                dangerouslySetInnerHTML={{ __html: chapterContent.description }}
            ></div>

            {/* <Box className="navigate-chapter" id="no-bg">
                <Button
                    className="navigate-chapter__button"
                    variant="outlined"
                    id="white-bg"
                >
                    Previous
                </Button>
                <Button
                    className="navigate-chapter__button"
                    variant="outlined"
                    id="white-bg"
                >
                    Next
                </Button>
            </Box> */}
            {/* <ContextMenu
  contextMenu={contextMenu}
  onClose={handleCloseContextMenu}
  onPin={handlePinComponent}
  onDictionary={handleOpenDictionary}
  menuType="text"
/> */}
        </Box>
    );
};

export default ChapterContent;
