export const BASE_URL = "https://api.mytrueskills.com/api";
//export const BASE_URL = "http://localhost:8000/api";
// export const BASE_URL = "https://api-staging.mytrueskills.com/api";

export const PRODUCTION_URL = "https://api.mytrueskills.com/api";

const SOCKET_URL = "https://api.mytrueskills.com";
// const SOCKET_URL = "http://localhost:8000";
// const SOCKET_URL = "https://api-staging.mytrueskills.com";

export const API_ENDPOINTS = {
  VALIDATE_TOKEN_API: `${BASE_URL}/user/validate-session`,
  LOGIN_API: `${BASE_URL}/user/login`,
  LOGIN_USING_GOOGLE_API: `${BASE_URL}/user/login-google-user`,
  CREATE_USER_API: `${BASE_URL}/user/create-user`,
  CREATE_USER_USING_GOOGLE_API: `${BASE_URL}/user/create-google-user`,
  SECRET_KEY: `${BASE_URL}/auth/get-secret-key`,
  GET_USER_INFO_BY_USER_ID: `${BASE_URL}/user/get-info`,
  EDIT_USER_INFO_AND_SAVE: `${BASE_URL}/user/save-user`,
  RESET_PASSWORD: `${BASE_URL}/user/reset-password`,
  FORGOT_PASSWORD: `${BASE_URL}/user/forgot-password`,
  RESET_AUTH_PASSWORD: `${BASE_URL}/user/save-forgot-password`,
  COURSES_FOR_USER: `${BASE_URL}/v1/courses/course`,
  AVAILABLE_COURSES_FOR_USER: `${BASE_URL}/v1/courses/available-course`,
  GET_VIDEO_URL: `${BASE_URL}/v1/media/course/video`,
  GET_THUMBNAIL: `${BASE_URL}/v1/media/course/thumbnail`,
  GET_IMAGE: `${BASE_URL}/v1/media/course/image`,
  GET_INVOICE: `${BASE_URL}/v1/media/invoice`,
  GET_PDF: `${BASE_URL}/v1/media/course/pdf`,
  APPLY_COUPON: `${BASE_URL}/v1/coupon/coupon-code`,
  RAYZORPAY_CREATE_ORDER: `${BASE_URL}/v1/payment/create-order`,
  RAYZORPAY_CREATE_ORDER_PUBLIC: `${BASE_URL}/v1/payment/create-order-public`,
  RAYZORPAY_VERIFY_PAYMENT: `${BASE_URL}/v1/payment/verify-payment`,
  RAYZORPAY_VERIFY_PAYMENT_PUBLIC: `${BASE_URL}/v1/payment/verify-payment-public`,
  RAYZORPAY_FAILED_PAYMENT: `${BASE_URL}/v1/payment/failed`,
  COURSE_REVIEWS: `${BASE_URL}/v1/courses/course/ratings`,
  COURSE_CHAPTER: `${BASE_URL}/v1/courses/chapter`,
  COURSE_FAQ: `${BASE_URL}/v1/courses/course/faq`,
  COURSE_ENROLLMENT_STATUS: `${BASE_URL}/v1/courses/update-enrollment-status`,
  COURSE_BASELINE_ASSESSMENT: `${BASE_URL}/v1/courses/take-assessment`,
  PAYMENT_STATUS: `${BASE_URL}/v1/payment/fetch-payment`,
  //private course api
  USER_COURSE_CHAPTER: `${BASE_URL}/v1/courses/course/course-view`,
  USER_COURSE_CHAPTER_DETAILS: `${BASE_URL}/v1/courses/course/course-lesson`,
  RECOMMENDED_COURSES: `${BASE_URL}/v1/courses/recommended-course`,
  LAST_ACCESSED_LESSON: `${BASE_URL}/v1/courses/last-accessed`,
  SAVE_LESSON_PROGRESS: `${BASE_URL}/v1/courses/course/course-status`,
  SAVE_LONG_ANSWER_QUIZ: `${BASE_URL}/v1/courses/long-answer-quiz`,
  SAVE_QUIZ_PROGRESS: `${BASE_URL}/v1/courses/quiz`,
  SAVE_OPTION_QUIZ_PROGRESS: `${BASE_URL}/v1/courses/option-answer-quiz`,
  SAVE_STORY_QUIZ_PROGRESS: `${BASE_URL}/v1/courses/story-answer-quiz`,
  SAVE_HTML_QUIZ_PROGRESS: `${BASE_URL}/v1/courses/html-answer-quiz`,
  FETCH_PAYMENT_HISTORY: `${BASE_URL}/v1/payment/history`,
  FETCH_QUIZ_STAT: `${BASE_URL}/v1/courses/quiz-stat`,

  // public api 
  PUBLIC_COURSE: `${BASE_URL}/v1/courses/public-view` ,

  // verify certificate 
  COURSE_CERTIFICATE_DETAILS: `${BASE_URL}/v1/courses/course-certificate`,
  CREATE_COURSE_COMPLETITION_CERTIFICATE: `${BASE_URL}/v1/courses/course/provide-certificate`,
  VERIFY_CERTIFICATE: `${BASE_URL}/v1/courses/certificate`,
  DOWNLOAD_CERTIFICATE: `${BASE_URL}/v1/media/certificate`,
  // API to store student feedback
  STORE_COURSE_FEEDBACK: `${BASE_URL}/v1/courses/course/feedback`,
  // API to get certificate and feedback
  GET_CERTIFICATE_AND_FEEDBACK: `${BASE_URL}/v1/courses/:slug/certificate-feedback`,

  PROFILE_PHOTO: `${BASE_URL}/v1/media/profile-pic`,

  // help centre api 
  HELP_CENTRE_CATEGORY:  `${BASE_URL}/v1/help-centre`,
  HELP_CENTRE_SUB_CATEGORY:  `${BASE_URL}/v1/help-centre-sub`,
  HELP_CENTRE_CQUESTION:  `${BASE_URL}/v1/help-centre-question`,
  FIND_HELP_CENTRE_QUESTION:  `${BASE_URL}/v1/find-help-centre-question`,


  // save report content 
  SAVE_REPORT_RAISE:  `${BASE_URL}/v1/save-report`,

  // notification
  GET_NOTIFICATION_INFO:  `${BASE_URL}/v1/app/notfication`,
  MARK_NOTIFICATION_INFO:  `${BASE_URL}/v1/app/notfication-mark`,
  CLICK_NOTIFICATION_INFO:  `${BASE_URL}/v1/app/notfication-click`,
  
  
  // chat room 
  GET_CHAT_ROOM_CHANNELS:  `${BASE_URL}/v1/app/chat-room`,
  GET_CHAT_ROOM_CHANNELS_MESSAGE:  `${BASE_URL}/v1/app/chat-members`,
  GET_CHAT_ROOM_CHANNELS_MESSAGE_COUNT:  `${BASE_URL}/v1/app/chat-members-count`,
  GET_CHAT_ROOM_CHANNELS_RECENT_MESSAGE:  `${BASE_URL}/v1/app/chat-members-message`,
  GET_CHAT_ROOM_FILE:  `${BASE_URL}/v1/media/chat-room-file`,
  UPDATE_CHAT_ROOM_MESSAGE:  `${BASE_URL}/v1/app/chat-edit-message`,
  DELETE_CHAT_ROOM_MESSAGE:  `${BASE_URL}/v1/app/chat-delete-message`,

 // discussion forum threads
  CREATE_NEW_THREAD: `${BASE_URL}/v1/app/create-new-thread`,
  GET_ALL_THREADS: `${BASE_URL}/v1/app/get-threads`,
  GET_SINGLE_THREAD: `${BASE_URL}/v1/app/get-thread-and-replies`,
  REPLY_TO_THREAD: `${BASE_URL}/v1/app/provide-reply-thread`,
  EDIT_THREAD: `${BASE_URL}/v1/app/edit-new-thread`,
  DELETE_THREAD_REPLY: `${BASE_URL}/v1/app/delete-reply-thread`,
  EDIT_THREAD_REPLY: `${BASE_URL}/v1/app/edit-reply-thread`,
  VIEW_ALL_BOOKMARKS: `${BASE_URL}/v1/app/view-all-book-mark`,
  VIEW_MY_THREADS: `${BASE_URL}/v1/app/view-all-my-threads`,
  TOGGLE_BOOKMARK: `${BASE_URL}/v1/app/toggle-book-mark`,
  TOGGLE_LIKE: `${BASE_URL}/v1/app/toggle-likes`,


  //auto save quiz
  POST_AUTO_SAVE_QUIZ :  `${BASE_URL}/v1/courses/auto-save`,
  GET_AUTO_SAVED_QUIZ_DATA:  `${BASE_URL}/v1/courses/auto-save`,
  //pin lesson content
  POST_PIN_LESSON_QUIZ :  `${BASE_URL}/v1/courses/pin`,
  GET_PINNED_LESSON_DATA:  `${BASE_URL}/v1/courses/pin`,
   
  SOCKET_URL: `${SOCKET_URL}`,
};
