import React, { useContext, useEffect, useState } from "react";
import { Header } from "components/common/header/Header";
import GridCard from "components/module/app/dashboard/GridCard";
import AvailableCourses from "components/module/app/myCourses/AvailableCourses";
import { Box, Grid, Typography } from "@mui/material";
import UserContext from "context/userContext/UserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import { SkeletonCard } from "components/common/skeleton/SkeletonCard";
import VideoPlayerYoutube from "components/common/videoPlayer/VideoPlayerYoutube";
import VideoPlayerDashboard from "components/common/videoPlayer/VideoPlayerDashboard";
import emoji from "../../../assets/images/emoji.jpg";
import VideoPlayerBunnyNet from "components/common/videoPlayer/VideoPlayerBunnyNet";

const DashboardPage = () => {
  const { userData, isLoading } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [courseData, setCourseData] = useState({});

  const {
    data: recommendedCoursesData,
    error: recommendedCoursesError,
    loading: loadingRecommendedCourses,
    getData: recommendedCoursesPayload,
  } = useGetRequest(API_ENDPOINTS.RECOMMENDED_COURSES);

  useEffect(() => {
    setThumbnailURL(`${API_ENDPOINTS.GET_IMAGE}/what_is_mts_zz3GIl4hL.png`)
  }, []);

  useEffect(() => {
    const fetchRecommendedCourses = async () => {
      if (!isLoading) {
        try {
          await recommendedCoursesPayload();
        } catch (err) {
          console.log("error:", err);
        }
      }
    };
    fetchRecommendedCourses();
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (!loadingRecommendedCourses && recommendedCoursesData) {
        const mergeCourseData = (availableCoursesDetails, CourseStatDashboard) => {
          return availableCoursesDetails.map((course) => {
            const courseStatDetails = CourseStatDashboard.find(
              (stat) => stat.course_id === course.course_id
            );
            return {
              ...course,
              avg_review: courseStatDetails?.avg_review,
            };
          });
        };

        const availableCourses = mergeCourseData(
          recommendedCoursesData.availableCoursesDetails || [],
          recommendedCoursesData.CourseStatDashboard || []
        );

        setCourseData({ availableCoursesDetails: availableCourses });
      }
    }
  }, [isLoading, recommendedCoursesData, loadingRecommendedCourses]);

  useEffect(() => {
    if (!isLoading && userData && userData.length > 0) {
      const userObject = userData.find(
        (item) => typeof item === "object" && "user_id" in item
      );
      if (userObject && userObject !== userInfo) {
        setUserInfo(userObject);
      }
    }
  }, [userData, isLoading, userInfo]);

  const fullName = userInfo ? `${userInfo.first_name} ${userInfo.last_name} ` : "";

  // Only render content when we have user data
  if (!userInfo) {
    return <Box sx={{ background: 'transparent' }} />; // Empty box with transparent background
  }

  return (
    <Box 
      className="dashbord-page"
      sx={{
        background: 'transparent', // Make background transparent
        '& .MuiBox-root': { // Target all nested Box components
          background: 'transparent'
        }
      }}
    >
      <Header
        title={
          <>
            Hello, <span style={{ color: "#39CCCC" }}>{fullName}</span>{" "}
            <img
              src={emoji}
              alt="emoji"
              style={{ width: "30px", height: "30px", verticalAlign: "baseline" }}
            />
          </>
        }
        subtitle="Your journey is just beginning—make it remarkable!"
        sx={{ background: 'transparent' }} // Make header background transparent
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h4" sx={{ color: "black", marginBottom: 2 }}>
            What is MTS?
          </Typography>
          <Box
            // sx={{
            //   borderRadius: "8px",
            //   minHeight: "360px",
            //   backgroundImage: `url('${thumbnailURL}') !important`,
            //   backgroundSize: "cover !important",
            //   backgroundPosition: "center !important",
            //   backgroundRepeat: "no-repeat !important",
            // }}
          >
            <VideoPlayerBunnyNet
              videoUrl={"26c3b6f5-b2c2-443f-b467-c37356c4a463"}
              thumbnailUrl={'26c3b6f5-b2c2-443f-b467-c37356c4a463/thumbnail.jpg'}
            />
          </Box>
        </Grid>
      </Grid>

      <GridCard />
    </Box>
  );
};

export default DashboardPage;