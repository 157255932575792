import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import LoadingContext from "context/loadingContext/LoadingContext";
import CourseDetailsContext from "context/courseDetailsContext/CourseDetailsContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import CreateAccount from "components/module/auth/CreateAccount";
import CoursePriceCard from "components/module/app/courseDetails/CoursePriceCard";
import { createAccountFormSchema, createAccountFormSchemaPublic } from "config/schemas/formSchema";
import { useFormik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import usePostRequest from "hooks/usePostRequest";
import { encryptData } from "utilityFunction/encryption&Decryption";
import UserContext from "context/userContext/UserContext";
import LoadingOverlay from "overlay/loadingOverlay";



const CoursePaymentPage = () => {
  //store the slug in a var
  const { slug } = useParams();
  const navigate = useNavigate();
  //state to store the slug
  const [storeSlug, setStoreSlug] = useState("");
  //state to store the video url
  const [videoURL, setVideoURL] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  //user context
  const { userData } = useContext(UserContext);

  const [showPassword, setShowPassword] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [courseInfo, setCourseInfo] = useState({});
  const [processingPayment, setProcessingPayment] = useState(false);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(false);
  const [paymentSuccessBlocked, setPaymentSuccessBlocked] = useState(false);
  const [cutOffPercentage, setPercentage] = useState(null);
  const [loadingCourseData1, setLoadingCourseData] = useState(true);

  const [orderDataAfterVerfication, setOrderDataAfterVerfication] =
    useState(null);
  const [paymentID, setPaymentID] = useState("");


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);
  const { isAppLoading } = useContext(LoadingContext);
  //context for course details
  const {
    // loadingCourseInformation,
    courseInformation,
    addDataTOCourseContext,
    clearDataFromCourseContext,
    loadingCourseInformation,
    setCourseSlug,
    courseDiscountPrice
    // setLoadingCourseInformation
  } = useContext(CourseDetailsContext);

  const [courseDetails, setCourseDetails] = useState(null);
  const [lessonDetails, setLessonDetails] = useState(null);
  const [hostType, setHostType] = useState("");

  //custom get req hook
  const {
    data: courseData,
    loading: loadingCourseData,
    error: errorLoadingCourseData,
    getData: courseDataPayload,
  } = useGetRequest(API_ENDPOINTS.COURSES_FOR_USER);

  //store the slub in a usestate
  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
      //add slug to course details context, this slug is used for redirecting to the course-details page after payment failure
      addDataTOCourseContext(slug);
    }
  }, [slug]);

  const {
    data: createOrderData,
    error: createOrderError,
    loading: loadingCreateOrder,
    postData: createOrderPayload,
  } = usePostRequest(API_ENDPOINTS.RAYZORPAY_CREATE_ORDER_PUBLIC);


  const {
    data: verifyOrderData,
    error: verifyOrderError,
    loading: loadingVerifyOrder,
    postData: verifyOrderPayload,
  } = usePostRequest(API_ENDPOINTS.RAYZORPAY_VERIFY_PAYMENT_PUBLIC);


  const { postData: failedPaymentPayload } = usePostRequest(API_ENDPOINTS.RAYZORPAY_FAILED_PAYMENT);

  const onSubmit = async (values, actions) => {
    const formattedValues = {
      ...values,

    };
    // console.log(
    //   "values from get request in create account form",
    //   formattedValues
    // );

    try {
      // Encrypt the first name last name date of birth and password and password
      const encryptedfirstName = encryptData(formattedValues.firstName.trim().toLowerCase());
      const encryptedlastName = encryptData(formattedValues.lastName.trim().toLowerCase());
      const encryptedEmail = encryptData(formattedValues.email.trim().toLowerCase());
      const encryptedPassword = encryptData(formattedValues.password.trim());

      const userPayload = {
        encryptedfirstName, encryptedlastName, encryptedEmail, encryptedPassword
      }
      handlePayment(userPayload);
    } catch (err) {
      console.log("error while creating account", err);
    }
    if (!loadingCreateOrder && createOrderData) {
      actions.resetForm();
    }
  };

  useEffect(() => {
    if (!loadingCourseInformation && courseInformation) {
      if (courseInformation) {
        setCourseInfo(courseInformation);
        setPercentage(courseInformation.cut_off_percentage);
        setLoadingCourseData(false);
      }
    }
  }, [courseInformation]);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (storeSlug) {
        try {
          await courseDataPayload(storeSlug);
        } catch (err) {
          if (err.response) {
            notifyUser(
              "Error",
              "An error occurred while fetching course details.",
              "error"
            );
          } else if (err.request) {
            // The request was made but no response was received
            notifyUser(
              "Network Error",
              "Network error, please try again.",
              "warning"
            );
          } else {
            // An error occurred in setting up the request
            notifyUser(
              "Unexpected Error",
              `Unexpected error: ${err.message}`,
              "error"
            );
          }
        }
      }
    };
    fetchCourseDetails();
  }, [isAppLoading, storeSlug]);

  useEffect(() => {
    if (verifyOrderData) {
      setOrderDataAfterVerfication(verifyOrderData);
      setLoadingCourseData(false);
    }
  }, [verifyOrderData]);


  useEffect(() => {
    if (orderDataAfterVerfication) {
      setProcessingPayment(false);
      navigate(`/payment/success/${paymentID}`);
      return;
    }
  }, [orderDataAfterVerfication]);
  useEffect(() => {
    if (!loadingCourseData) {
      if (errorLoadingCourseData) {
        console.log(
          "error while fetching the course details of the course",
          errorLoadingCourseData
        );
      } else if (courseData) {
        //set the value to the local state
        setCourseDetails(courseData.courseDetails);
        setLessonDetails(courseData.courseStat);
        //set the course context also
        addDataTOCourseContext(courseData.courseDetails);

      } else {
        // console.log("error with the get request data from course-details page");
      }
    }
  }, [courseData, loadingCourseData]);

  const {
    errors,
    handleBlur,
    touched,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,

  } = useFormik({
    //local state
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    //validate the email and password( client side validation)
    validationSchema: createAccountFormSchemaPublic,
    //onSubmit handler
    onSubmit,
  });



  // Modify handlePayment to store payment success status
  const handlePayment = async (userPayload) => {
    const intendedUrl = referralCode
      ? `/app/course/${storeSlug}?referral=${referralCode}`
      : `/app/course/${storeSlug}`;

    sessionStorage.setItem('intendedUrl', intendedUrl);

    if (!loadingCourseInformation && courseInfo && !loadingCreateOrder) {
      try {
        setProcessingPayment(true);
        const amount = courseDiscountPrice ? courseDiscountPrice : courseInfo.price;
        localStorage.setItem('coursePrice', amount);

        const payload = {
          amount: amount.toString(),
          currency: "INR",
          course_id: courseInfo.course_id.toString(),
          userPayload
        };

        await createOrderPayload(payload);
      } catch (err) {
        setProcessingPayment(false);
        if (err.response) {
          notifyUser(
            "Order Error",
            "An error occurred while creating the order.",
            "error"
          );
        } else if (err.request) {
          notifyUser(
            "Network Error",
            "Network error, please try again.",
            "warning"
          );
        } else {
          notifyUser(
            "Unexpected Error",
            `Unexpected error: ${err.message}`,
            "error"
          );
        }
      }
    }
  };

  const handlePaymentProcess = () => {
    // setOpenAuthModal(false);
    Navigate(`/login`);
  };
  useEffect(() => {
    const storedPaymentSuccess = localStorage.getItem('paymentSuccess');
    const storedOrderID = localStorage.getItem('razorpay_order_id');
    const storedPaymentID = localStorage.getItem('razorpay_payment_id');
    const storedSignature = localStorage.getItem('razorpay_signature');

    if (storedPaymentSuccess === 'true' && storedOrderID && storedPaymentID && storedSignature) {
      (async () => {
        try {
          console.log('Verifying payment after refresh...');
          setLoadingOverlayVisible(true);
          await verifyOrderPayload({
            razorpay_order_id: storedOrderID,
            razorpay_payment_id: storedPaymentID,
            razorpay_signature: storedSignature,
          });
          console.log('Payment verified successfully after refresh.');

          // Clear stored data after verification
          localStorage.removeItem('paymentSuccess');
          localStorage.removeItem('razorpay_order_id');
          localStorage.removeItem('razorpay_payment_id');
          localStorage.removeItem('razorpay_signature');
        } catch (error) {
          console.error('Error verifying payment:', error);
        } finally {
          setLoadingOverlayVisible(false);
        }
      })();
    }
  }, []); // Run only on mount

  useEffect(() => {
    if (createOrderData) {
      setLoadingCourseData(false);
      setPaymentID(createOrderData.payment.payment_id);
      setProcessingPayment(true);

      const amount = courseDiscountPrice ? courseDiscountPrice : courseInfo.price;
      localStorage.setItem('paymentSuccess', 'true');
      localStorage.setItem('paymentSuccessCourseSlug', storeSlug);

      const options = {
        key: userData.REACT_APP_RAYZOR_KEY,
        amount: amount * 100, // Razorpay expects amount in paise
        currency: "INR",
        name: "MTS LMS",
        description: courseInfo.course_name,
        image: 'https://mytrueskills.com/images/resources/logo.png',
        order_id: createOrderData.orderId,
        handler: async (response) => {
          console.log('Payment success, storing details in localStorage.');

          // Store payment verification details
          localStorage.setItem('razorpay_order_id', response.razorpay_order_id);
          localStorage.setItem('razorpay_payment_id', response.razorpay_payment_id);
          localStorage.setItem('razorpay_signature', response.razorpay_signature);

          try {
            setLoadingOverlayVisible(true);
            await verifyOrderPayload({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });
            console.log('Payment verification completed.');

            // Clear stored data after successful verification
            localStorage.removeItem('paymentSuccess');
            localStorage.removeItem('razorpay_order_id');
            localStorage.removeItem('razorpay_payment_id');
            localStorage.removeItem('razorpay_signature');
          } catch (verificationError) {
            console.error('Payment verification failed:', verificationError);
          } finally {
            setLoadingOverlayVisible(false);
          }

          razorpay.close();
        },
        modal: {
          confirm_close: false,
          escape: true,
        },
        // prefill: {
        //   name: userData[0]?.first_name,
        //   email: userData[0]?.email,
        //   contact: userData[0]?.contact_number,
        // },
        notes: {
          address: courseInfo.course_name,
        },
        theme: {
          color: "#39cccc",
        },
      };

      const razorpay = new window.Razorpay(options);

      razorpay.on("payment.failed", async function (response) {
        console.error("Payment failed:", response.error);
        try {
          await failedPaymentPayload({
            response: response.error,
            course_id: courseInfo.course_id,
          });
          // notifyUser("Payment Failed", "Payment failed.", "error");
        } catch (error) {
          console.error("Error sending failed payment:", error);
        }
        setProcessingPayment(false);
        setCourseSlug(storeSlug);
      });

      razorpay.open();
    }
  }, [createOrderData]);

  return (
    courseDetails && (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={8}>
          <Box className="create-account-public-layout">

            {/* <Typography
                  component="h1"
                  variant="h5"
                  className=" text header-text header-text-create-account"
                >
                  Create Account
                </Typography>
                <Typography
                  variant="h6"
                  mb={1}
                  className="text header-caption header-caption-create-account"
                >
                  Start Learning with MTS by creating an account
                </Typography> */}
            <Box mt={2} component="form" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                    First Name
                  </InputLabel>
                  <TextField
                    placeholder="John"
                    size="small"
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    type="text"
                    id="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                    }}
                  />
                  {errors.firstName && touched.firstName ? (
                    <Typography className="error-input_login">
                      {errors.firstName}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                    Last Name
                  </InputLabel>
                  <TextField
                    placeholder="Doe"
                    size="small"
                    required
                    fullWidth
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="family-name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                    }}
                  />
                  {errors.lastName && touched.lastName ? (
                    <Typography className="error-input_login">
                      {errors.lastName}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                    Email
                  </InputLabel>
                  <TextField
                    placeholder="john@gmail.com"
                    size="small"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                    }}
                  />
                  {errors.email && touched.email ? (
                    <Typography className="error-input_login">
                      {errors.email}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                    Password
                  </InputLabel>
                  <TextField
                    placeholder="••••••••"
                    sx={{ overflow: "hidden" }}
                    size="small"
                    name="password"
                    required
                    fullWidth
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff sx={{ mr: 2, width: "50%" }} />
                            ) : (
                              <Visibility sx={{ mr: 2, width: "50%" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && touched.password ? (
                    <Typography className="error-input_login">
                      {errors.password}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>

              </Grid>
              <Button
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                className="button button-create-account"
              >
                Purchase Now
              </Button>
              <Grid container justifyContent="center" mt={2}>
                <Grid item>
                  <Typography className="create-account">
                    Already have an account?
                    <Link to="/login">Login</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: {
              xs: "static", // For extra-small screens (mobile)
              sm: "static", // For small screens (tablet)
              md: "sticky", // For medium screens and larger
            },
            marginTop: "61px",
          }}
        >
          <CoursePriceCard courseDetails={courseDetails} lessonDetails={lessonDetails} Slug={storeSlug} />
        </Grid>
        {loadingOverlayVisible && <LoadingOverlay message="Processing payment..." />}
      </Grid>
    )
  );
};

export default CoursePaymentPage;