import React, { useState } from "react";
import {
    Card,
    CardContent,
    Button,
    Typography,
    Box,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Paper,
    Divider,
} from "@mui/material";
import { CalendarToday, Close } from "@mui/icons-material";
import { formatDate } from "utilityFunction/formatDate";
import { IoTimeOutline } from "react-icons/io5";

const formatDateWithMonth = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "short" });
    const daySuffix =
        day % 10 === 1 && day !== 11
            ? "st"
            : day % 10 === 2 && day !== 12
                ? "nd"
                : day % 10 === 3 && day !== 13
                    ? "rd"
                    : "th";
    return `${day}${daySuffix} ${month} ${date.getFullYear()}`;
};

const capitalizeText = (text) => {
    if (!text) return text;
    return text
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

const QuizResultCard = ({ title, date, score, status, data }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let isValidScore = null, totalFeedbackScore = null, totalQuestionScore = null, totalPercentage = null;

    if (Array.isArray(data) && data.length > 0) {
        isValidScore = (score) => score !== null && score !== undefined;

        totalFeedbackScore = data.reduce(
            (acc, item) => isValidScore(item.feedback_score) ? acc + item.feedback_score : acc,
            0
        );

        totalQuestionScore = data.reduce(
            (acc, item) => isValidScore(item.question_score) ? acc + item.question_score : acc,
            0
        );

        totalPercentage = totalQuestionScore
            ? ((totalFeedbackScore / totalQuestionScore) * 100).toFixed(0)
            : null;
    } else {
        totalFeedbackScore = null;
        totalQuestionScore = null;
        totalPercentage = null;
    }

    return (
        <>
            <Card className="quiz_result_wrapper">
                <Card
                    className={`quiz-result-card ${score
                        ? "quiz-result-card_border-green"
                        : "quiz-result-card_border-grey"
                        }`}
                >
                    <Box sx={{ flexGrow: 1, p: { xs: 1, sm: 2 } }}>
                        <Typography variant="h6" className="quiz-result-card_quiz-name">
                            {title}
                        </Typography>
                        <Box className="quiz-result-card__subbox" sx={{ mt: 2 }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6} md={3}>
                                    <Button
                                        variant="outlined"
                                        className="SAQ_wrapper-navigate__button"
                                        onClick={handleOpen}
                                        fullWidth
                                        sx={{
                                            height: { xs: '32px', sm: '40px' },
                                            fontSize: { xs: '0.875rem', sm: '1rem' },
                                            padding: { xs: '4px 8px', sm: '6px 16px' },
                                            minWidth: { xs: '100px', sm: 'auto' },
                                            whiteSpace: 'nowrap',
                                            mx: { xs: 'auto', sm: 0 },
                                            width: { xs: '80%', sm: '100%' }
                                        }}
                                    >
                                        View Answers
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <CalendarToday />
                                        <Typography variant="body1">{formatDate(date)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    {totalFeedbackScore > 0 && (
                                        <Typography variant="body1">
                                            Your Score: {totalPercentage}%
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ width: '80%', height: '40px' }}>
                                        <Button
                                            variant="contained"
                                            className={`quiz-result-card__subbox-button quiz-result-card__subbox-button--${score ? "green" : "grey"}`}
                                            fullWidth
                                            sx={{
                                                height: '100%',
                                                padding: '1px 1px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                transform: 'translateX(-10px)',
                                                cursor: "none !important",
                                                textTransform: 'capitalize !important'
                                            }}
                                            
                                        >
                                            {status}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Card>
            </Card>

            <Dialog
                maxWidth="lg"
                fullWidth
                PaperProps={{ style: { height: "80vh", overflowX: "hidden" } }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} className="quiz-result-card_quiz-name">
                            {title}
                        </Grid>
                        {totalPercentage > 0 && (
                            <Grid item xs={3} className="quiz-result-card_quiz-name">
                                {/* <Typography variant="body1">
                                    Your Score: {totalPercentage}%
                                </Typography> */}
                            </Grid>
                        )}
                        <Grid item xs={3} className="quiz-result-card_quiz-name">
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <CalendarToday />
                                <Typography
                                    variant="body1"
                                    className="quiz-result-card_quiz-name"
                                >
                                    {formatDateWithMonth(date)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            {data && data[0] && data[0].completion_time && (
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <IoTimeOutline />
                                    <Typography variant="body1">
                                        {parseFloat(data[0].completion_time).toFixed(0)} Mins
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        sx={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Paper elevation={3}>
                    <Box p={3} sx={{ boxShadow: 0, outline: "none", border: "none" }}>
                        <Grid container spacing={3}>
                            {data && data[0] && data[0].stQuestion && (
                                <Typography variant="h6" className="quiz-view-all-modal">
                                    <span dangerouslySetInnerHTML={{ __html: data[0].stQuestion.replace(/&nbsp;/g, ' ') }} />
                                </Typography>
                            )}

                            {(Array.isArray(data) && data.length > 0) ? (
                                data.map((item, index) => (
                                    <Grid item xs={12} className="quiz-view-all-modal" key={index}>
                                        <Typography variant="h6">
                                            {index + 1}. <span dangerouslySetInnerHTML={{
                                                __html: item.question.replace(/&nbsp;/g, " "),
                                            }} />
                                        </Typography>
                                        <Box mt={2} p={2} className="quiz-view-all-modal__answer">
                                            <Typography variant="subtitle1">Your Answer:</Typography>
                                            {item && item.option_name ?
                                                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: capitalizeText(item.option_name.replace(/&nbsp;/g, ' '))
                                                    }} /> - <span dangerouslySetInnerHTML={{
                                                        __html: capitalizeText(item.answer.replace(/&nbsp;/g, ' '))
                                                    }} />
                                                </Typography>
                                                : <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: capitalizeText(item.answer.replace(/&nbsp;/g, ' '))
                                                    }} />
                                                </Typography>
                                            }
                                            {/* {item.feedback_score && item.question_score && (
                                                <Typography variant="subtitle1" mt={2}>
                                                    Score: {item.feedback_score}/{item.question_score}
                                                </Typography>
                                            )} */}
                                        </Box>
                                        {item.feedback && (
                                            <Box mt={2} p={2} className="quiz-view-all-modal__feedback">
                                                <Typography variant="subtitle1">Feedback</Typography>
                                                <Typography variant="body1">{item.feedback}</Typography>
                                                <Typography variant="subtitle2" mt={2}>
                                                    {formatDateWithMonth(item.feedback_submission_time)}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                ))
                            ) : (
                                <Typography variant="subtitle1">No data available</Typography>
                            )}
                        </Grid>
                    </Box>
                </Paper>
            </Dialog>
        </>
    );
};

export default QuizResultCard;
