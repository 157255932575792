import React, { useState, useContext, useRef, useEffect } from "react";
import LessonContext from "context/lessonContext/LessonContext";
import UserContext from "context/userContext/UserContext";

const VideoPlayerBunnyNet = ({ videoUrl, thumbnailUrl, content }) => {
  const { updateLessonCompletionData } = useContext(LessonContext);
  const { userData } = useContext(UserContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInstitutionUser, setIsInstitutionUser] = useState(false);
  const playerRef = useRef(null);

  useEffect(() => {
    if (content) {
      updateLessonCompletionData(content);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(userData) && userData[0] && userData[0].is_institution_user === 1) {
      setIsInstitutionUser(true);
    } else {
      setIsInstitutionUser(false);
    }
  }, [userData]);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className="bunney-net-player" style={{ position: "relative", width: "100%", height: "100%" }}>
      {!isPlaying && (
        <div
          className="react-player__preview"
          onClick={handlePlay}
          style={{ 
            position: "relative", 
            cursor: "pointer",
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        

          }}
        >
          <img
            src={`https://vz-fefc3984-6fd.b-cdn.net/${thumbnailUrl}`}
            alt="Video Thumbnail"
            className="thumbnail"
            style={{
              position: "absolute",
              //top: 0,
              //left: 0,
              width: "100%",
              height: "100%",
              dipslay : "flex",
              justifyContent: "center",
              alignItems: "center",
         
              

          
              //objectFit: "cover",
            }}
          />
          <button
            style={{
              width: "64px",
              height: "64px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #39cccc",
              backgroundColor: "white",
              borderRadius: "50%",
              cursor: "pointer",
              transition: "background-color 0.3s ease, transform 0.2s ease",
              position: "relative",
              zIndex: 2
            }}
            aria-label="Play"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#39cccc"
              viewBox="0 0 24 24"
              width="60"
              height="60"
            >
              <path d="M8 5v14l11-7z"></path>
            </svg>
          </button>
        </div>
      )}
      {isPlaying && (
        <iframe
          src={`https://iframe.mediadelivery.net/embed/370995/${videoUrl}?autoplay=false&loop=false&muted=${isInstitutionUser ? "true" : "false"}&preload=true&responsive=true`}
          loading="lazy"
          className="responsive-iframe"
          style={{ 
            width: "100%", 
            height: "60vh", 
            maxWidth: "100%", 
            border: "none",
            borderRadius: "15px"
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default VideoPlayerBunnyNet;