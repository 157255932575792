import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import AdminRoutes from "routes";
import CustomizedSnackbars from "components/common/snackBar/SnackBar";
//import DictionaryTooltip from "Dictionary/Dictionary";
import "styles/styles.scss";
import useClarity from "utilityFunction/clarity";

function App() {
  
  useClarity();
  return (
    <>
      <RouterProvider router={AdminRoutes} />
      <CustomizedSnackbars />
      {/* <DictionaryTooltip /> */}
    </>
  );
}

export default App;
